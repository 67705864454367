import React from 'react'
import PropTypes from 'prop-types'
import Broadcast from '../Broadcast'
import './index.scss'

const mapBroadcasts = broadcast =>
  <Broadcast
    key={broadcast.uuid}
    uuid={broadcast.uuid}
    status={broadcast.level}
    body={broadcast.body}
  />

const Broadcasts = ({ broadcasts }) =>
  <div className='broadcast-messages'>
    {broadcasts.map(mapBroadcasts)}
  </div>

Broadcasts.propTypes = {
  broadcasts: PropTypes.array.isRequired
}

export default Broadcasts
