export const ACCOUNT = 'account'
export const ADDTAG = 'addtag'
export const ADMIN = 'admin'
export const AMGT = 'amgt'
export const APPS = 'apps'
export const ARC = 'arc'
export const ARROW_DOWN = 'arrow-down'
export const ARROW_RIGHT = 'arrow-right'
export const AUTOCLEAR = 'autoclear'
export const CLOSE = 'close'
export const COMPANY = 'company'
export const CONTACT_USER = 'contact-user'
export const DEFAULT_APP = 'app-default'
export const DMV123 = 'dmv123'
export const DPO = 'dpo'
export const ELT = 'elt'
export const ERROR = 'error'
export const EXTERNAL = 'external'
export const JSI = 'jsi'
export const MENU = 'menu'
export const NEWS = 'news'
export const NOTIFICATIONS = 'notifications'
export const SHADOW_MAN = 'spyguy'
export const SQ_INFO = 'sq-info'
export const SUCCESS = 'success'
export const TITLECHECK = 'titlecheck'
export const TNPORTAL = 'tnportal'
export const TRI_WARNING = 'tri-warn'
export const TRI_WARNING_LINE = 'tri-warn-line'
export const VSQ = 'app-icon-experimental'
