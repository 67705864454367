import { takeLatest, put, call } from 'redux-saga/effects'
import service from '../service/getContactUsers'
import { GET_CONTACT_USERS } from '../constants/actionTypes'
import * as actions from '../actions/session'

export default function * contactUsers (action) {
  yield takeLatest(GET_CONTACT_USERS, fetchData)
}

function * fetchData (action) {
  const users = yield call(service)
  try {
    yield put(actions.getContactUsersSuccess(users))
  } catch (error) {
    yield put(actions.getContactUsersFailure(error))
  }
}
