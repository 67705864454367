import React from 'react'
import PropTypes from 'prop-types'

import NavBarIcon from '../NavBarIcon'
import NotificationsIcon from '../NotificationsIcon'
import AddLogo from '../AddLogo'
import NavBarDropMenu from '../NavBarDropMenu'
import * as links from '../../constants/links'
import * as dropMenuTypes from '../../constants/dropMenuTypes'
import './index.scss'

import { translateComponent } from '../../util/translate'
const t = translateComponent('NavBar')

const renderShadowInfo = (isShadowUser, companyName, userRealName, companyCredit) => {
  if (!isShadowUser) return null
  return (
    <div className='admin-shadow-info'>
      <div>
        <span className='user-name-shadow-info'>
          {userRealName}
        </span>
        <span className={'shadow-info-balance ' + (companyCredit < 0 ? 'credit-negative' : 'credit-positive')}>
          ${companyCredit + ' '}
        </span>
      </div>
      <div>
        <span className='company-name-shadow-info'>
          {companyName + ' '}
        </span>
      </div>
    </div>
  )
}

const AccountSection = ({ isLoggedIn, initialized, isShadowUser, companyName, userRealName, companyCredit }) => {
  if (!isLoggedIn && initialized) return null
  return (
    <div className='account-info-section'>
      {renderShadowInfo(isShadowUser, companyName, userRealName, companyCredit)}
      <div className='navbar-icons'>
        <NavBarIcon dropMenuType={dropMenuTypes.APPS} />
        <NotificationsIcon />
        <NavBarIcon dropMenuType={dropMenuTypes.ACCOUNT} />
      </div>
      <NavBarDropMenu />
    </div>
  )
}

const DemoMode = ({ isEnabled }) => {
  if (!isEnabled) return null

  return (
    <div className='addnav-demo-mode-section'>
      <div>
        <div className='addnav-demo-mode-label'>
          {t('demoMode')}
        </div>
        <div className='addnav-demo-mode-link'>
          <button onClick={() => window.ADD123.demo.disable()} className='addnav-demo-mode-disable'>{t('disableDemoMode')}</button>
        </div>
      </div>
    </div>
  )
}

const NavBar = ({ isLoggedIn, initialized, isShadowUser, userRealName, companyName, companyCredit, demoModeIsEnabled }) =>
  <div className={'addnav-navbar' + (isShadowUser ? ' shadowing' : '') + (demoModeIsEnabled ? ' addnav-demo-mode' : '')}>
    <div className='add-logo-section'><AddLogo link={isLoggedIn ? links.DASHBOARD : ''} linkLabel={t('dashboardLinkLabel')} /></div>
    <DemoMode isEnabled={demoModeIsEnabled} />
    <AccountSection
      isShadowUser={isShadowUser}
      companyName={companyName}
      userRealName={userRealName}
      companyCredit={companyCredit}
      isLoggedIn={isLoggedIn}
      initialized={initialized}
    />
  </div>

NavBar.propTypes = {
  isShadowUser: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  initialized: PropTypes.bool.isRequired,
  userRealName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  companyCredit: PropTypes.string.isRequired
}

AccountSection.propTypes = {
  isShadowUser: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  initialized: PropTypes.bool.isRequired,
  userRealName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  companyCredit: PropTypes.string.isRequired
}

export default NavBar
