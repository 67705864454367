/* eslint-disable */
const CROSS_DOMAINS = [
  'carlocate.com',
  'titlecheck.us',
  'secureelt.com',
  'securetitleelt.com'
]

export default function () {
  (function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
      (i[r].q = i[r].q || []).push(arguments)
    }, i[r].l = 1 * new Date(); a = s.createElement(o),
      m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
  })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga', { 'allowLinker': true })
  ga('create', 'UA-7166507-1', 'auto')
  ga('require', 'linker');
  ga('linker:autoLink', CROSS_DOMAINS);
  ga('set', 'page', window.location.pathname)
  ga('send', 'pageview')
}
