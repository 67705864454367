import * as types from '../constants/actionTypes'
import * as callback from '../util/sessionExpiredCallback'

const initialState = {
  ignoreCache: false,
  loginRedirect: true,
  sessionExpiredCallback: callback.get()
}

const reducer = function (state = initialState, action) {
  switch (action.type) {
    case types.SET_OPTIONS:
      return {
        ...state,
        ignoreCache: action.ignoreCache,
        loginRedirect: action.loginRedirect,
        sessionExpiredCallback: action.sessionExpiredCallback
      }

    case types.SESSION_LOAD_SUCCESS:
      return {
        ...state
      }

    default:
      return state
  }
}

export default reducer
