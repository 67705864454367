import React from 'react'
import SubBar from '../SubBar'
import SubSubBar from '../SubSubBar'
import NavBar from '../NavBar'
import NewsBriefManager from '../NewsBrief'
import JailedNotification from '../JailedNotification'
import Broadcasts from '../Broadcasts'
import AboutToExpireModal from '../AboutToExpireModal'
import AccountSearch from '../AccountSearch'
import './index.scss'

const AppLayout = () => {
  return (
    <div>
      <nav>
        <NavBar />
        <SubBar />
        <SubSubBar />
      </nav>
      <AccountSearch />
      <Broadcasts />
      <NewsBriefManager />
      <JailedNotification />
      <AboutToExpireModal />
    </div>
  )
}

export default AppLayout
