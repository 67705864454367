const isErrorString = s => {
  if (s.indexOf('INPUT_MISSING') !== -1 && s.indexOf('session-id') !== -1) return true
  if (s.indexOf('SESSION_NOT_FOUND') !== -1) return true
  return false
}

export default function isSessionExpiredError (error) {
  if (typeof error === 'string') return isErrorString(error)
  return isErrorString(error.message)
}
