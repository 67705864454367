import Broadcast from './Broadcast'
import { useDispatch } from 'react-redux'
import * as actions from '../../actions/session'
import { useCallback } from 'react'

const Component = props => {
  const dispatch = useDispatch()

  const onKeyUp = useCallback(ev => {
    if (ev.keyCode === 13) {
      dispatch(actions.closeNotification(props.uuid))
    }
  }, [props.uuid, dispatch])

  const closeMessage = useCallback(() => {
    dispatch(actions.closeNotification(props.uuid))
  }, [props.uuid, dispatch])

  return (
    <Broadcast {...props} onKeyUp={onKeyUp} closeMessage={closeMessage} />
  )
}

export default Component
