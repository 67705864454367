const removePunctuation = str =>
  !str ? null : str.replace(/[.,'"()]/g, '').toUpperCase()

const isExactMatch = searchText => result =>
  searchText && (
    (removePunctuation(result?.username?.toUpperCase()) === searchText.toUpperCase()) ||
    (removePunctuation(result?.realName?.toUpperCase()) === searchText.toUpperCase()) ||
    (removePunctuation(result?.companyName?.toUpperCase()) === searchText.toUpperCase()))

const compareExact = searchText => (a, b) => {
  const aIsExact = isExactMatch(searchText)(a)
  const bIsExact = isExactMatch(searchText)(b)
  if (aIsExact && bIsExact) return 0
  if (aIsExact) return -1
  if (bIsExact) return 1
  return 0
}

const compareResult = (a, b) => {
  const aStr = a?.realName ? a.realName.toUpperCase() : a.companyName?.toUpperCase()
  const bStr = b?.realName ? b.realName.toUpperCase() : b.companyName?.toUpperCase()
  if (aStr > bStr) return 1
  return (aStr < bStr) ? -1 : 0
}

const combinedCompare = searchText => (a, b) => {
  const value = compareExact(searchText)(a, b)
  if (value) return value
  return compareResult(a, b)
}

const sortQuickActions = (results, searchText) => {
  if (!results) return
  return results.sort(combinedCompare(removePunctuation(searchText)))
}

export default sortQuickActions
