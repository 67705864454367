import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon'
import * as notificationTypes from '../../constants/notificationTypes'
import * as notificationStatuses from '../../constants/notificationStatuses'
import * as iconTypes from '../../constants/iconTypes'
import './index.scss'
import translate from '../../util/translate'
import dateFormat from '../../util/dateFormat'
import Markdown from '../Markdown'

const t = translate('Notification')

const typeToStyle = type => type && 'addnav-notification-' + type.replace('_', '-').toLowerCase()

const determineIcon = (type, status) => {
  if (type === notificationTypes.NEWS_BRIEF) return iconTypes.NEWS
  switch (status) {
    case notificationStatuses.ERROR: return iconTypes.TRI_WARNING_LINE
    case notificationStatuses.WARNING: return iconTypes.TRI_WARNING
    case notificationStatuses.SUCCESS: return iconTypes.SUCCESS
    default: return iconTypes.SQ_INFO
  }
}

const DEFAULT_BROADCAST_TITLE = 'broadcast_message'

const determineTitle = (type, status, title) => {
  if (type === notificationTypes.NEWS_BRIEF) return t('news')
  if (!title || title === DEFAULT_BROADCAST_TITLE) {
    switch (status) {
      case notificationStatuses.ERROR: return t('error')
      case notificationStatuses.WARNING: return t('warning')
      case notificationStatuses.SUCCESS: return t('success')
      default: return t('info')
    }
  }
  return title
}

const style = (status, type, read, uuid) => {
  let s = 'addnav-notification ' + typeToStyle(status) + ' ' + typeToStyle(type) + ' addnav-notification-' + uuid
  if (!read) s += ' unread'
  return s
}

const Notification = ({ uuid, title, type, status, date, body, onClick, read, onKeyUp }) => {
  if (type !== notificationTypes.NEWS_BRIEF) {
    onClick = null
  }

  return (
    <div tabIndex={0} className={style(status, type, read, uuid)} onClick={onClick} onKeyUp={onKeyUp}>
      <Icon type={determineIcon(type, status)} />
      <h2 className='addnav-notification-header'>{determineTitle(type, status, title)}</h2>
      <time>{dateFormat(date)}</time>
      {type === notificationTypes.NEWS_BRIEF ? <p>{title}</p> : <Markdown source={body} />}
    </div>
  )
}

Notification.propTypes = {
  onClick: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  read: PropTypes.bool.isRequired,
  onKeyUp: PropTypes.func.isRequired
}

export default Notification
