import AccountSearch from './AccountSearch'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../actions/quickNavigate'
import quickActionSelector from '../../selectors/quickActionSelector'
import KeyNavigator from './KeyNavigator'
import { useCallback, useEffect } from 'react'

const createKeyHandler = quickNavigateSetVisible => event => {
  if (event.target.nodeName === 'BODY' && event.which === 190) {
    quickNavigateSetVisible(true)
  } else if (event.which === 27) {
    quickNavigateSetVisible(false)
  } else if (event.which === 13) {
    quickNavigateSetVisible(false)
  }
}

let keyHandler

const enable = quickNavigateSetVisible => {
  if (!keyHandler) keyHandler = createKeyHandler(quickNavigateSetVisible)
  document.addEventListener('keyup', keyHandler)
}

const disable = () => document.removeEventListener('keyup', keyHandler)

const Component = () => {
  const dispatch = useDispatch()
  const nProps = useSelector(s => ({
    quickActions: quickActionSelector(s),
    loading: s.quickNavigate.loading,
    search: s.quickNavigate.value,
    visible: s.quickNavigate.visible,
    isAdmin: s.user.isAdmin
  }))

  const quickActions = nProps.quickActions

  const onSelect = useCallback(index => {
    if (quickActions.length && quickActions[index]) {
      window.location.assign(quickActions[index].url)
    }
  }, [quickActions])

  const onCancel = useCallback(() => {
    dispatch(actions.quickNavigateSetVisible(false))
  }, [dispatch])

  const getTotal = useCallback(() => quickActions.length, [quickActions])

  const onChange = useCallback(ev => {
    dispatch(actions.quickNavigateSetValue(ev.target.value))
  }, [dispatch])

  const closeIfClickedOn = useCallback(ev => {
    if (ev.currentTarget === ev.target) {
      dispatch(actions.quickNavigateSetVisible(false))
    }
  }, [dispatch])

  const close = useCallback(() => {
    dispatch(actions.quickNavigateSetVisible(false))
  }, [dispatch])

  const handleSearchChange = useCallback(ev => {
    dispatch(actions.quickNavigateSetValue(ev.target.value))
  }, [dispatch])

  const accountSearchClear = useCallback(ev => {
    dispatch(actions.quickNavigateSetValue(''))
  }, [dispatch])

  useEffect(() => {
    enable(x => dispatch(actions.quickNavigateSetVisible(x)))

    return () => disable()
  }, [dispatch])

  if (!nProps.isAdmin || !nProps.visible) return null

  return (
    <KeyNavigator onSelect={onSelect} onCancel={onCancel} getTotal={getTotal}>
      <AccountSearch
        {...nProps}
        onChange={onChange}
        closeIfClickedOn={closeIfClickedOn}
        close={close}
        handleSearchChange={handleSearchChange}
        accountSearchClear={accountSearchClear}
      />
    </KeyNavigator>
  )
}
export default Component
