import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon'
import * as iconTypes from '../../constants/iconTypes'
import './index.scss'
import { translateComponent } from '../../util/translate'

const t = translateComponent('FoldingSection')

const Header = ({ children, toggleFolded, folded, onKeyUp }) =>
  <span tabIndex={0} title={t('section') + ' ' + children} className='addnav-section-header' onClick={toggleFolded} onKeyUp={onKeyUp}>
    <Icon type={folded ? iconTypes.ARROW_RIGHT : iconTypes.ARROW_DOWN} />
    <span className='addnav-header-label'>{children}</span>
  </span>

const FoldingSection = ({ label, children, additionalClassName }) => {
  const [folded, setFolded] = useState(true)

  const handleKeyUp = useCallback(ev => {
    if (ev.keyCode === 13) {
      setFolded(f => !f)
    }
  }, [setFolded])

  return (
    <div className={'addnav-folding-section' + (additionalClassName ? ' ' + additionalClassName : '')}>
      <Header toggleFolded={() => setFolded(f => !f)} onKeyUp={handleKeyUp} folded={folded}>
        {label}
      </Header>
      <div className='addnav-folding-section-content'>
        {folded ? null : children}
      </div>
    </div>
  )
}

Header.propTypes = {
  folded: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  toggleFolded: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func.isRequired
}

FoldingSection.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  additionalClassName: PropTypes.string
}

export default FoldingSection
