const INITIAL = { uuid: false, title: false, type: false, otherAllowedScripts: false, redirectUrl: false }

const hasJail = state => state.sessionCache && state.sessionCache.jails && state.sessionCache.jails.length

const selectJailWithHighestPriorityOrOldestDate = jails => jails.reduce((jailA, jailB) => {
  if (jailA.priority > jailB.priority) return jailA
  if (jailA.priority === jailB.priority && jailA.addedOn < jailB.addedOn) return jailA
  return jailB
})

const getActiveJailSelector = state => {
  if (!hasJail(state)) return INITIAL
  const jail = selectJailWithHighestPriorityOrOldestDate(state.sessionCache.jails)
  return {
    uuid: jail.uuid,
    title: jail.title,
    type: jail.type,
    otherAllowedScripts: jail.otherAllowedScripts,
    redirectUrl: jail.redirectUrl
  }
}

export default getActiveJailSelector
