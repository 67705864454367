import React, { useEffect } from 'react'
import FocusLock from 'react-focus-lock'

import './index.scss'

const OverlayModal = ({ children }) => {
  useEffect(() => {
    document.body.classList.add('active-modal')
    return () => {
      document.body.classList.remove('active-modal')
    }
  }, [])

  return (
    <FocusLock>
      <div className='confirmation-modal'>
        <div className='center-screen'>
          {children}
        </div>
      </div>
    </FocusLock>
  )
}

export default OverlayModal
