const isActiveModule = (activeName, module) => module.code.toLowerCase() === activeName.toLowerCase()

const reduceAppsToActiveApp = activeName => (subModules, module) => {
  if (subModules) return subModules
  return isActiveModule(activeName, module) ? module.subModules : undefined
}

const getSubApps = (appName, modules) => (modules || []).reduce(reduceAppsToActiveApp(appName), null) || []

export {
  isActiveModule,
  reduceAppsToActiveApp
}

export default getSubApps
