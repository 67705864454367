import { takeLatest, put, call, select, delay } from 'redux-saga/effects'
import * as actions from '../actions/session'
import { SESSION_LOAD_SUCCESS } from '../constants/actionTypes'
import get from '../util/get'
import checkSessionService from '../service/checkSession'
import isSessionExpiredError from '../util/isSessionExpiredError'
import * as timeoutSettings from '../constants/sessionTimeoutSettings'

export default function * sessionCheck () {
  yield takeLatest(SESSION_LOAD_SUCCESS, handleCheckSession)
}

function * handleCheckSession (action) {
  let timeLeft = get(action, 'session.timeLeft') || 0

  while (true) {
    try {
      removeWarningPrefixFromTitle()
      timeLeft = timeLeft || 0

      if (timeLeft < 1) {
        yield put(actions.sessionExpired(action.loginRedirect))
        return
      }

      if (timeLeft < timeoutSettings.TIMEOUT_WARNING_DURATION) {
        yield call(handleAboutToExpire, timeLeft, action.loginRedirect)
        return
      }

      yield delay(determinePollDelay(timeLeft))

      timeLeft = yield call(checkSessionOnServer)

      yield put(actions.updateTimeLeft(timeLeft))
    } catch (error) {
      if (isSessionExpiredError(error)) {
        yield put(actions.sessionExpired())
        return
      }
      yield delay(timeoutSettings.MINIMUM_DELAY)
    }
  }
}

export const addWarningPrefix = title => '* ' + (title || '')

export const titleHasWarningPrefix = title => (title || '').startsWith('* ')

export const removeWarningPrefix = title => (title || '').replace(/^(\* )+/, '')

export const removeWarningPrefixFromTitle = () => {
  window.document.title = removeWarningPrefix(window.document.title)
}

export const toggleTitleWarning = () => {
  const title = window.document.title || ''
  window.document.title = titleHasWarningPrefix(title) ? removeWarningPrefix(title) : addWarningPrefix(title)
}

function * handleAboutToExpire (timeLeft, loginRedirect) {
  const expireAt = new Date().valueOf() + timeLeft
  while (true) {
    if (timeLeft < 0) {
      yield put(actions.sessionExpired())
      return
    }
    const refreshingSession = yield select(state => state.refreshingSession)
    if (refreshingSession) {
      removeWarningPrefixFromTitle()
      return
    }
    yield put(actions.updateTimeLeft(timeLeft))
    yield call(toggleTitleWarning)
    yield delay(1000)
    timeLeft = expireAt - new Date().valueOf()
  }
}

function determinePollDelay (timeLeft) {
  return Math.max(timeLeft - timeoutSettings.TIMEOUT_WARNING_DURATION, timeoutSettings.MINIMUM_DELAY)
}

function * checkSessionOnServer () {
  const res = yield call(checkSessionService)
  const err = get(res, 'xmlerror.message', false)
  if (err) throw new Error(err)
  return res.timeLeft
}
