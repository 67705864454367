import * as types from '../constants/cacheTypes'
import * as keys from '../constants/cacheKeys'

const CACHE_KEY_PREFIX = 'ADDNAV_CACHE:'

const safeJsonParse = s => {
  try {
    return JSON.parse(s)
  } catch (err) {
    return null
  }
}

export const getCache = type => key => {
  const storageImpl = type === types.PERSISTENT ? window.localStorage : window.sessionStorage
  const finalKey = CACHE_KEY_PREFIX + key
  return {
    update: data => {
      try {
        return storageImpl.setItem(finalKey, JSON.stringify(data))
      } catch (err) {
        console.log(`ADDNav ${type} Cache: failed to setItem [${finalKey}] `, err && err.message)
      }
    },
    get: () => {
      try {
        return safeJsonParse(storageImpl.getItem(finalKey))
      } catch (err) {
        console.log(`ADDNav ${type} Cache: failed to getItem [${finalKey}] `, err && err.message)
        return null
      }
    },
    reset: () => {
      try {
        storageImpl.removeItem(finalKey)
      } catch (err) {
        console.log(`ADDNav ${type} Cache: failed to removeItem [${finalKey}] `, err && err.message)
        return null
      }
    }
  }
}

export const overriddenUrlCache = getCache(types.PERSISTENT)(keys.OVERRIDDEN_URLS)

export const baseUrlCache = getCache(types.PERSISTENT)(keys.BASE_URL)

export const legacyModuleCache = getCache(types.SESSION)(keys.LEGACY_MODULES)

export const readSessionBroadcastCache = getCache(types.SESSION)(keys.SESSION_BROADCAST)

export const sessionTrackingIdCache = getCache(types.SESSION)(keys.SESSION_TRACKING_ID)

export const jailRedirectCache = getCache(types.SESSION)(keys.JAIL_REDIRECT)

export const featureFlagsCache = getCache(types.PERSISTENT)(keys.FEATURE_FLAGS)

export const jailBrokenCache = getCache(types.SESSION)(keys.JAIL_BROKEN)
