import {
  SET_OPTIONS
} from '../constants/actionTypes'

export const setOptions = (ignoreCache, loginRedirect, sessionExpiredCallback) => ({
  type: SET_OPTIONS,
  ignoreCache,
  loginRedirect,
  sessionExpiredCallback
})
