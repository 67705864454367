import SubBar from './SubBar'
import { connect } from 'react-redux'

import getSubApps from '../../util/getSubApps'

const mapStateToProps = state => {
  const subModules = getSubApps(state.appName, state.legacyModules)
  return {
    parent: state.appName,
    appName: state.subAppName,
    modules: subModules
  }
}

export default connect(mapStateToProps)(SubBar)
