import { takeEvery, call, select } from 'redux-saga/effects'
import service from '../service/closeNotification'
import * as types from '../constants/actionTypes'
import * as notificationTypes from '../constants/notificationTypes'
import { readSessionBroadcastCache } from '../util/cache'

export default function * closeNotification () {
  yield takeEvery(types.CLOSE_NOTIFICATION, handleCloseNotification)
}

const reduceGetNotification = uuid => (selected, notification) => {
  if (selected) return selected
  if (notification.uuid === uuid) return notification
}

const notificationSelector = uuid => state => state.notifications.reduce(reduceGetNotification(uuid), null)

const shouldMarkReadInSessionOnly = (notification, action) =>
  notification.type === notificationTypes.SESSION_BROADCAST || action.remind

function * handleCloseNotification (action) {
  const notification = yield select(notificationSelector(action.uuid))

  if (!notification) return

  if (shouldMarkReadInSessionOnly(notification, action)) {
    handleSessionBroadcast(action.uuid)
  } else {
    yield call(callService, action)
  }
}

const handleSessionBroadcast = uuid => {
  let cache = readSessionBroadcastCache.get() || []
  cache = cache.filter(id => id !== uuid)
  cache.push(uuid)
  readSessionBroadcastCache.update(cache)
}

function * callService (action) {
  try {
    yield call(service, action.uuid)
  } catch (error) {
    console.log(error)
  }
}
