import * as types from '../constants/actionTypes'

const initialState = {
  users: [],
  loading: false,
  error: ''
}

const getContactUsers = (state, action) => ({
  ...state,
  loading: true
})

const getContactUsersSuccess = (state, action) => ({
  ...state,
  users: action.users,
  loading: false
})

const getContactUsersFailure = (state, action) => ({
  ...state,
  loading: false,
  error: action.error

})

const contactUsers = function (state = initialState, action) {
  switch (action.type) {
    case types.GET_CONTACT_USERS: return getContactUsers(state, action)
    case types.GET_CONTACT_USERS_SUCCESS: return getContactUsersSuccess(state, action)
    case types.GET_CONTACT_USERS_FAILURE: return getContactUsersFailure(state, action)
    default:
      return state
  }
}

export default contactUsers
