const reduceObject = params => {
  return Object.keys(params)
    .reduce((qs, key, value) => `${qs}&${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`, '')
    .substring(1)
}

export function encode (params) {
  if (!Array.isArray(params)) return reduceObject(params)
  return params
    .reduce((qs, value, key) => `${qs}&${encodeURIComponent(key)}=${encodeURIComponent(value)}`, '')
    .substring(1)
}

export function decode (query) {
  throw new Error('unimplemented')
}
