import NotificationsIcon from './NotificationsIcon'
import { connect } from 'react-redux'

const reduceUnreadCount = (count, notification) => notification.read ? count : ++count

const mapStateToProps = state => ({
  unReadCount: state.notifications.reduce(reduceUnreadCount, 0)
})

export default connect(mapStateToProps, null)(NotificationsIcon)
