import { SESSION_EXPIRE } from '../util/uris'
import mock from './mocks/expireSession'
import ajax from '../util/ajax'

export default function expireSession (onlyIfOldSession) {
  const data = {
    onlyIfOldSession
  }
  return ajax.post({ data, url: SESSION_EXPIRE, mock: mock })
}
