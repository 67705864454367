import NavBarDropMenu from './NavBarDropMenu'
import { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../actions/navBar'

let keyHandler

const createKeyHandler = showMenu => event => {
  if (showMenu && event?.which === 27) {
    showMenu('')
  }
}

const enable = showMenu => {
  if (!keyHandler) keyHandler = createKeyHandler(showMenu)
  document.addEventListener('keyup', keyHandler)
}

const disable = () => document.removeEventListener('keyup', keyHandler)

const Component = props => {
  const dispatch = useDispatch()
  const nProps = useSelector(s => ({
    apps: s.apps,
    menuName: s.menuName
  }))
  props = { ...props, ...nProps }

  const showMenu = useCallback(() => {
    dispatch(actions.showMenu())
  }, [dispatch])

  useEffect(() => {
    enable(props.showMenu)

    return () => disable()
  }, [props.showMenu])

  return (
    <NavBarDropMenu {...props} showMenu={showMenu} />
  )
}

export default Component
