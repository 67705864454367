import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

const BlankTargetLink = ({ href, children }) => <a href={href} target='_blank' rel='noreferrer'>{children}</a>

const markDownRenderers = {
  link: BlankTargetLink
}

const Markdown = ({ source }) =>
  <ReactMarkdown renderers={markDownRenderers}>
    {source}
  </ReactMarkdown>

Markdown.propTypes = {
  source: PropTypes.string.isRequired
}

BlankTargetLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.any
}

export default Markdown
