import * as types from '../constants/actionTypes'

export const load = ignoreCache => ({
  type: types.SESSION_LOAD,
  ignoreCache
})

export const loadSuccess = session => ({
  type: types.SESSION_LOAD_SUCCESS,
  session
})

export const loadFail = error => ({
  type: types.SESSION_LOAD_FAIL,
  error
})

export const updateTimeLeft = timeLeft => ({
  type: types.UPDATE_TIME_LEFT,
  timeLeft: timeLeft !== 0 && !timeLeft ? -1 : timeLeft
})

export const sessionExpired = () => ({
  type: types.SESSION_EXPIRED
})

export const setApplication = (appName, subAppName, subSubAppName) => ({
  type: types.SESSION_SET_APPLICATION,
  appName,
  subAppName,
  subSubAppName
})

export const closeNotification = (uuid, remind = false) => ({
  type: types.CLOSE_NOTIFICATION,
  uuid,
  remind
})

export const openNewsBrief = uuid => ({
  type: types.OPEN_NEWS_BRIEF,
  uuid
})

export const getContactUsers = () => ({
  type: types.GET_CONTACT_USERS
})

export const getContactUsersSuccess = users => ({
  type: types.GET_CONTACT_USERS_SUCCESS,
  users
})

export const getContactUsersFailure = error => ({
  type: types.GET_CONTACT_USERS_FAILURE,
  error
})

export const setJailBroken = isBroken => ({
  type: types.SET_JAIL_BROKEN,
  isJailBroken: isBroken
})
