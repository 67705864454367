import React from 'react'
import PropTypes from 'prop-types'
import '../../addicons.css'

const Icon = ({ type, isActive }) =>
  <span className={'addicon addicon-' + type + ' ' + (isActive ? 'active-icon' : '')} />

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  isActive: PropTypes.bool
}

export default Icon
