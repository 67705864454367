import React from 'react'
import PropTypes from 'prop-types'
import * as links from '../../constants/links'
import './index.scss'

import { translateComponent } from '../../util/translate'
import formatAccountNumber from '../../util/formatAccountNumber'

const t = translateComponent('AccountInformation')

const ShadowingMessage = ({ isShadowUser, isJailedUser }) => {
  if (!isShadowUser) return null
  return (
    <div className='shadow-user-message'>
      {isJailedUser ? <JailedMessage /> : null}
    </div>
  )
}

const JailedMessage = () =>
  <div>
    User Jailed <a href={links.BREAK_JAIL}>BREAK JAIL</a>
  </div>

const AddCreditLink = ({ addCreditLink }) => {
  if (!addCreditLink) return null
  return (
    <span className='add-credit-link'>
      <a href={addCreditLink.url} rel='noreferrer' target='_blank'>{t('add')}</a>
    </span>
  )
}

const AccountInformation = ({ userRealName, companyName, accountNumber, companyCredit, isShadowUser, isJailedUser, addCreditLink }) =>
  <div className='link-information'>
    <div className='user-name'>{userRealName}</div>
    <div className='company-name'>{companyName}</div>
    <div className='account-number'>{t('accountNumber') + ': ' + formatAccountNumber(accountNumber)}</div>
    <div className='company-credit'>
      <span className='balance-title'>{t('balance') + ': '}</span>
      <span tabIndex={0} title={t('balance') + ' ' + companyCredit} className={'balance ' + (companyCredit < 0 ? 'credit-negative' : 'credit-positive')}>
        ${companyCredit}
      </span>
      <AddCreditLink addCreditLink={addCreditLink} />
    </div>
    <ShadowingMessage isShadowUser={isShadowUser} isJailedUser={isJailedUser} />
  </div>

AccountInformation.propTypes = {
  userRealName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  accountNumber: PropTypes.number.isRequired,
  companyCredit: PropTypes.string.isRequired,
  isShadowUser: PropTypes.bool.isRequired,
  isJailedUser: PropTypes.bool.isRequired,
  addCreditLink: PropTypes.object
}

ShadowingMessage.propTypes = {
  isShadowUser: PropTypes.bool.isRequired,
  isJailedUser: PropTypes.bool.isRequired
}

AddCreditLink.propTypes = {
  addCreditLink: PropTypes.object
}

export default AccountInformation
