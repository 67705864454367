/* eslint-disable react/prop-types */
import React from 'react'

const OPTIONS = {
  behavior: 'instant',
  block: 'center',
  inline: 'nearest',
  alignToTop: false
}

const withScrollIntoView = Comp => props => {
  const setRef = ref => {
    if (props.selected && ref) {
      ref.scrollIntoView(OPTIONS)
    }
  }
  return <div ref={setRef}><Comp {...props} /></div>
}

export default withScrollIntoView
