import MenuLink from './MenuLink'
import { useCallback, useState } from 'react'

const Component = props => {
  const [loading, setLoading] = useState(false)
  const onClick = props.onClick

  const handleClick = useCallback(ev => {
    setLoading(true)
    if (onClick) onClick(ev)
  }, [setLoading, onClick])

  return (
    <MenuLink {...props} loading={loading} handleClick={handleClick} />
  )
}
export default Component
