import { applyMiddleware } from 'redux'
import invariant from 'redux-immutable-state-invariant'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import createReduxWaitForMiddleware from 'redux-wait-for-action'

export const sagaMiddleware = createSagaMiddleware()

const middlewareEnhancer = () => {
  const middleware = []

  if (process.env.NODE_ENV !== 'production') {
    const logger = createLogger({
      level: 'log',
      duration: true,
      collapsed: true,
      diff: true
    })

    middleware.push(invariant())
    middleware.push(logger)
  }

  middleware.push(createReduxWaitForMiddleware())
  middleware.push(sagaMiddleware)

  return applyMiddleware(...middleware)
}

export default middlewareEnhancer
