import ApplicationsMenu from './ApplicationsMenu'
import { connect } from 'react-redux'

const mapDispatchToProps = dispatch => ({})

const mapStateToProps = state => ({
  apps: state.apps
})

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationsMenu)
