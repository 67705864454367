import React, { useReducer } from 'react'

const UP = 'UP'
const DOWN = 'DOWN'
const RESET = 'RESET'

export const context = React.createContext(0)

const up = () => ({ type: UP })

const down = total => ({ type: DOWN, total })

const reset = () => ({ type: RESET })

const increment = (index, total) => Math.min(((index || 0) + 1), total - 1)

const decrement = index => Math.max(((index || 0) - 1), 0)

const reducer = (state = 0, action) => {
  switch (action.type) {
    case UP: return decrement(state)
    case DOWN: return increment(state, action.total)
    case RESET: return 0
    default: return state
  }
}

const KeyNavigator = ({ children, getTotal, onCancel, onSelect }) => {
  const [index, dispatch] = useReducer(reducer, 0)

  const onKeyDown = event => {
    switch (event.key) {
      case 'Escape':
        dispatch(reset())
        onCancel()
        return
      default:
    }

    if (!getTotal()) return

    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault()
        dispatch(up())
        return
      case 'ArrowDown':
        event.preventDefault()
        dispatch(down(getTotal()))
        return
      case 'Tab':
        event.preventDefault()
        if (event.shiftKey) {
          dispatch(up())
        } else {
          dispatch(down(getTotal()))
        }
        return
      case 'Enter':
        event.preventDefault()
        onSelect(index)
        break
      default:
    }
  }

  return (
    <context.Provider value={index}>
      <div tabIndex='0' onKeyDown={onKeyDown}>
        {children}
      </div>
    </context.Provider>
  )
}

export default KeyNavigator
