import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon'
import * as iconTypes from '../../constants/iconTypes'
import Highlight from '../Highlight'
import './index.scss'

const style = (active, selected) => {
  let s = 'account-summary'
  if (!active) s += ' disabled'
  if (selected) s += ' selected'
  return s
}

const stripHash = url => url.replace(/^#(.*)/, '$1')

const UserResult = props =>
  <a onClick={props.onClick} href={stripHash(props.url)} className={style(props.active, props.selected)}>
    <Icon type={props.isMaster ? iconTypes.CONTACT_USER : iconTypes.ACCOUNT} />
    <h2>{props.realName} ({props.username})</h2>
    <div><Highlight search={props.search}>{props.username}</Highlight> &ndash; {props.companyName}</div>
  </a>

const CompanyResult = props =>
  <a onClick={props.onClick} href={stripHash(props.url)} className={style(props.active, props.selected)}>
    <Icon type={iconTypes.COMPANY} />
    <h2>{props.companyName}</h2>
    <div><Highlight search={props.search}>{props.companyName}</Highlight> &ndash; {props.city}, {props.state}</div>
  </a>

const TransactionResult = props =>
  <a onClick={props.onClick} href={stripHash(props.url)} className={style(props.active, props.selected)}>
    <Icon type={iconTypes.EXTERNAL} />
    <h2>{props.additionalFields?.transData} ({props.additionalFields?.transType})</h2>
    <div>{props.companyName} - {props.city}, {props.state}</div>
  </a>

const LinkResult = props =>
  <a onClick={props.onClick} href={stripHash(props.url)} className={style(props.active, props.selected)}>
    <Icon type={iconTypes.EXTERNAL} />
    <h2>{props.title}</h2>
    <div>{props.url}</div>
  </a>

const AccountSearchResult = props => {
  if (props.type === 'USER') return <UserResult {...props} />
  if (props.type === 'COMPANY') return <CompanyResult {...props} />
  if (props.type === 'TRANSACTION') return <TransactionResult {...props} />
  return <LinkResult {...props} />
}

LinkResult.propTypes = {
  selected: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}

TransactionResult.propTypes = {
  selected: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  additionalFields: PropTypes.object.isRequired
}

UserResult.propTypes = {
  selected: PropTypes.bool.isRequired,
  search: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  realName: PropTypes.string,
  username: PropTypes.string,
  companyName: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}

CompanyResult.propTypes = {
  selected: PropTypes.bool.isRequired,
  search: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}

AccountSearchResult.propTypes = {
  url: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  search: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  realName: PropTypes.string,
  username: PropTypes.string,
  companyName: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  additionalFields: PropTypes.object
}

export default AccountSearchResult
