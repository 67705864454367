import React from 'react'
import PropTypes from 'prop-types'
import AccountInformation from '../AccountInformation'
import mapMenuLink from '../../util/mapMenuLink'
import './index.scss'

import { translateComponent } from '../../util/translate'
const t = translateComponent('AccountMenu')

const AccountMenu = ({ links, logout, onKeyUp }) =>
  <div className='account-menu'>
    <AccountInformation />
    {links.map(mapMenuLink)}
    <div tabIndex={0} className='logout-section' onClick={logout} onKeyUp={onKeyUp}>{t('logout')}</div>
  </div>

AccountMenu.propTypes = {
  logout: PropTypes.func.isRequired,
  links: PropTypes.array.isRequired,
  onKeyUp: PropTypes.func.isRequired
}

export default AccountMenu
