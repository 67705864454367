import { takeEvery, call, select, put, delay } from 'redux-saga/effects'
import * as types from '../constants/actionTypes'
import * as sessionExpiredCallback from '../util/sessionExpiredCallback'
import { redirectToLogin } from '../util/redirect'
import expireSessionService from '../service/expireSession'
import selector from '../selector'
import * as actions from '../actions/session'

const LOAD_DELAY = 1000
const ONLY_IF_OLD_SESSION = true

export default function * sessionExpired () {
  yield takeEvery(types.SESSION_EXPIRED, handleSessionExpired)
}

function * handleSessionExpired (action) {
  try {
    yield call(sessionExpiredCallback.invoke)
  } catch (err) {
    console.log(err)
  }
  const loginRedirect = yield select(selector('options.loginRedirect'))
  if (loginRedirect) {
    yield call(handleRedirectToLogin, loginRedirect)
  }
}

function * handleRedirectToLogin (loginRedirect) {
  const username = yield select(selector('user.userName'))
  try {
    const expireResponse = yield call(expireSessionService, ONLY_IF_OLD_SESSION)

    /*
     * Since ONLY_IF_OLD_SESSION was true, the session might still be active after
     * the expire request.
     */
    if (expireResponse.sessionState === 'ACTIVE') {
      yield call(handleSessionStillActive)
      return
    }
  } catch (err) {
    console.log(err)
  }

  yield call(redirectToLogin, username)
}

function * handleSessionStillActive () {
  yield put(actions.updateTimeLeft(-1))
  yield delay(LOAD_DELAY)
  const ignoreCache = true
  yield put(actions.load(ignoreCache))
}
