import React from 'react'
import PropTypes from 'prop-types'
import DropMenu from '../DropMenu'
import ApplicationsMenu from '../ApplicationsMenu'
import Notifications from '../Notifications'
import AccountMenu from '../AccountMenu'
import * as dropMenuTypes from '../../constants/dropMenuTypes'

const showMenuWrapper = (showMenu, originalMenuName, menuNameToChangeTo) => ev => (
  showMenu(originalMenuName === menuNameToChangeTo ? '' : menuNameToChangeTo)
)

const getDropMenuChildElement = menuName => {
  switch (menuName) {
    case dropMenuTypes.APPS: return <ApplicationsMenu />
    case dropMenuTypes.NOTIFICATIONS: return <Notifications />
    case dropMenuTypes.ACCOUNT: return <AccountMenu />
    default: return <div />
  }
}

const NavBarDropMenu = ({ menuName, showMenu }) => {
  if (!menuName) return null
  return (
    <DropMenu onOffClick={showMenuWrapper(showMenu, menuName, '')}>
      {getDropMenuChildElement(menuName)}
    </DropMenu>
  )
}

NavBarDropMenu.propTypes = {
  showMenu: PropTypes.func.isRequired,
  menuName: PropTypes.string.isRequired
}

export default NavBarDropMenu
