import ajax from '../util/ajax'
import { ACCOUNT_SEARCH } from '../util/uris'
import mock from './mocks/accountSearch'

export default function accountSearch (search) {
  if (search.length < 3) return Promise.resolve([])
  return ajax.post({
    url: ACCOUNT_SEARCH,
    data: {
      onlyActive: true,
      search: search
    },
    mock: mock
  })
}
