import { sagaMiddleware } from '../store/middlewareEnhancer'
import loadSession from './loadSession'
import cacheLegacyModules from './cacheLegacyModules'
import closeNotification from './closeNotification'
import sessionCheck from './sessionCheck'
import sessionExpired from './sessionExpired'
import accountSearch from './accountSearch'
import contactUsers from './contactUsers'
import setJailBroken from './setJailBroken'

export default function sagas () {
  sagaMiddleware.run(loadSession)
  sagaMiddleware.run(cacheLegacyModules)
  sagaMiddleware.run(closeNotification)
  sagaMiddleware.run(sessionCheck)
  sagaMiddleware.run(sessionExpired)
  sagaMiddleware.run(accountSearch)
  sagaMiddleware.run(contactUsers)
  sagaMiddleware.run(setJailBroken)
}
