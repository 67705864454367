import React from 'react'
import PropTypes from 'prop-types'

import './index.scss'

const Wrapper = ({ link, linkLabel, children }) => {
  if (!link) return <span className='add-logo'>{children}</span>
  return <a href={link} className='add-logo' title={linkLabel}>{children}</a>
}

const AddLogo = ({ link, linkLabel }) =>
  <Wrapper link={link} linkLabel={linkLabel}>
    <div className='addicon addicon-addlogo-blue' />
    <div className='addicon-addlogo-shine' />
    <div className='addicon addicon-addlogo-black' />
  </Wrapper>

Wrapper.propTypes = {
  children: PropTypes.any.isRequired,
  link: PropTypes.string,
  linkLabel: PropTypes.string
}

AddLogo.propTypes = {
  link: PropTypes.string,
  linkLabel: PropTypes.string
}

export default AddLogo
