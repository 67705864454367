import Broadcasts from './Broadcasts'
import { connect } from 'react-redux'
import * as types from '../../constants/notificationTypes'

const mapDispatchToProps = dispatch => ({})

const isUnreadBroadcast = n => (n.type === types.BROADCAST || n.type === types.SESSION_BROADCAST) && !n.read

const mapStateToProps = state => ({
  broadcasts: state.notifications.filter(isUnreadBroadcast)
})

export default connect(mapStateToProps, mapDispatchToProps)(Broadcasts)
