import * as actions from '../../actions/session'
import { useDispatch, useSelector } from 'react-redux'
import AboutToExpireModal from './AboutToExpireModal'
import { useMemo, useCallback } from 'react'

const convertTimeLeftToSeconds = timeLeft => {
  if (timeLeft > 0) return (Math.round(timeLeft / 1000) - 1).toFixed(0)
  return ''
}

const Component = () => {
  const dispatch = useDispatch()
  const timeLeft = useSelector(s => s.timeLeft)
  const refreshing = useSelector(s => s.refreshingSession)
  const timeLeftSeconds = useMemo(() => convertTimeLeftToSeconds(timeLeft), [timeLeft])

  const load = useCallback(ev => {
    ev.preventDefault()
    dispatch(actions.load(true))
  }, [dispatch])

  return (
    <AboutToExpireModal refreshing={refreshing} timeLeftSeconds={timeLeftSeconds} load={load} />
  )
}

export default Component
