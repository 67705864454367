export const SESSION_LOAD = 'SESSION_LOAD'
export const SESSION_LOAD_SUCCESS = 'SESSION_LOAD_SUCCESS'
export const SESSION_LOAD_FAIL = 'SESSION_LOAD_FAIL'
export const UPDATE_TIME_LEFT = 'UPDATE_TIME_LEFT'
export const SESSION_EXPIRED = 'SESSION_EXPIRED'
export const OPEN_NEWS_BRIEF = 'OPEN_NEWS_BRIEF'
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION'
export const SESSION_SET_APPLICATION = 'SESSION_SET_APPLICATION'
export const SET_OPTIONS = 'SET_OPTIONS'
export const SHOW_MENU = 'SHOW_MENU'
export const QUICK_NAVIGATE_SET_VALUE = 'QUICK_NAVIGATE_SET_VALUE'
export const QUICK_NAVIGATE_SET_VISIBLE = 'QUICK_NAVIGATE_SET_VISIBLE'
export const ACCOUNT_SEARCH = 'ACCOUNT_SEARCH'
export const ACCOUNT_SEARCH_SUCCESS = 'ACCOUNT_SEARCH_SUCCESS'
export const ACCOUNT_SEARCH_FAIL = 'ACCOUNT_SEARCH_FAIL'
export const GET_CONTACT_USERS = 'GET_CONTACT_USERS'
export const GET_CONTACT_USERS_SUCCESS = 'GET_CONTACT_USERS_SUCCESS'
export const GET_CONTACT_USERS_FAILURE = 'GET_CONTACT_USERS_FAILURE'
export const SET_JAIL_BROKEN = 'SET_JAIL_BROKEN'
