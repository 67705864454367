let root
/*
 * catch window not defined for testing environments
 */
try {
  root = window
} catch (err) {
  root = {}
}
root.ADD123 = {}
export default root.ADD123
