import optionsReducer from './options'
import contactUserReducer from './contactUsers'
import { legacyModuleCache, readSessionBroadcastCache } from '../util/cache'
import * as types from '../constants/actionTypes'
import * as notificationTypes from '../constants/notificationTypes'
// import { reducer as withKeyNavigationReducer, STORE_DOMAIN as withKeyNavigationDomain } from '../util/withKeyNavigation'

const initialUser = {
  uuid: '',
  userName: '',
  realName: '',
  isJailed: false,
  isShadowUser: false,
  isAdmin: false,
  emailAddress: '',
  fax: '',
  phone: ''
}

const initialCompany = {
  uuid: '',
  name: '',
  accountNumber: '',
  companyType: '',
  useDbaName: false,
  coLicNo: '',
  billingCode: 222,
  credit: '0.00',
  dbaName: '',
  dealerLicense: '',
  phone: '',
  fax: '',
  feid: '',
  mailingAddress: {
    street: '',
    city: '',
    state: '',
    zip: ''
  },
  physicalAddress: {
    street: '',
    city: '',
    county: '',
    state: '',
    zip: ''
  },
  reportAs: '',
  useMailingAddress: false
}

const primaryInitialState = {
  isLoggedIn: false,
  initialized: false,
  refreshingSession: false,
  appName: '',
  menuName: '',
  options: {},
  apps: [],
  links: [],
  confirmationModal: '',
  legacyModules: [],
  notifications: [],
  serverTime: '',
  timeLeft: -1,
  user: initialUser,
  company: initialCompany,
  sessionCache: null,
  quickNavigate: {
    visible: false,
    loading: false,
    value: '',
    accountSearch: []
  },
  isJailBroken: false
}

const legacyIntitalState = {
  subAppName: '',
  subSubAppName: ''
}

const initialState = {
  ...primaryInitialState,
  ...legacyIntitalState
}

const markReadIfInReadArray = readArray => notification => {
  return (readArray || []).includes(notification.uuid) ? { ...notification, read: true } : notification
}

const applyReadSessionBroadcasts = notifications => {
  return notifications.map(markReadIfInReadArray(readSessionBroadcastCache.get()))
}

const sortBySticky = (a, b) => Number(b.sticky) - Number(a.sticky)
const resetReadState = notifications => {
  if (notifications.length === 0) return []

  notifications.sort(sortBySticky)
  if (notifications[0].sticky) {
    notifications[0].read = false
  }
  return notifications
}

const applyInitResponseToState = (state, resp) => {
  if (!resp) return state

  const apps = resp.apps || []

  const links = resp.links || []
  const user = { ...initialUser, ...(resp.user || {}) }
  const company = { ...initialCompany, ...(resp.company || {}) }
  const notifications = applyReadSessionBroadcasts(resetReadState(resp.notifications) || [])
  return {
    ...state,
    isLoggedIn: !!user.uuid,
    initialized: true,
    legacyModules: resp.legacyModules || [],
    apps,
    links,
    serverTime: resp.serverTime || '',
    timeLeft: resp.timeLeft || 0,
    sessionCache: resp,
    notifications,
    company,
    user,
    quickNavigate: state.quickNavigate,
    refreshingSession: false
  }
}

const sessionLoadSuccess = (state, action) => applyInitResponseToState(state, action.session)

const setApplication = (state, action) => ({
  ...state,
  appName: action.appName || '',
  subAppName: action.subAppName || '',
  subSubAppName: action.subSubAppName || ''
})

const showMenu = (state, action) => ({
  ...state,
  menuName: action.menuName
})

const updateNotificationReadStatus = (uuid, status) => n => n.uuid === uuid ? { ...n, read: status } : n

const openNewsBrief = (state, action) => ({
  ...state,
  notifications: state.notifications.map(updateNotificationReadStatus(action.uuid, false))
})

const closeNotification = (state, action) => ({
  ...state,
  notifications: state.notifications.map(updateNotificationReadStatus(action.uuid, true))
})

const quickNavigateSetValue = (state, action) => ({
  ...state,
  quickNavigate: {
    ...state.quickNavigate,
    value: action.value || ''
  }
})

const isUnreadNewsBrief = notification => notification.type === notificationTypes.NEWS_BRIEF && !notification.read

const hasUnreadNewsBrief = state => !!state.notifications.filter(isUnreadNewsBrief).length

const quickNavigateSetVisible = (state, action) => ({
  ...state,
  quickNavigate: {
    ...state.quickNavigate,
    visible: (!hasUnreadNewsBrief(state) && action.visible) || false,
    value: action.visible ? state.quickNavigate.value : ''
  }
})

const accountSearch = (state, action) => ({
  ...state,
  quickNavigate: {
    ...state.quickNavigate,
    loading: true
  }
})

const accountSearchFailure = (state, action) => ({
  ...state,
  quickNavigate: {
    ...state.quickNavigate,
    loading: false,
    accountSearch: []
  }
})

const accountSearchSuccess = (state, action) => ({
  ...state,
  quickNavigate: {
    ...state.quickNavigate,
    loading: false,
    accountSearch: action.accounts || []
  }
})

const insertCachedLegacyModules = () => {
  const legacyModules = legacyModuleCache.get()
  if (!legacyModules) return initialState
  const state = { ...initialState }
  state.legacyModules = legacyModules
  return state
}

const setJailBroken = (state, action) => ({
  ...state,
  isJailBroken: action.isJailBroken
})

const root = (state = insertCachedLegacyModules(), action) => {
  switch (action.type) {
    case types.SESSION_LOAD_SUCCESS: return sessionLoadSuccess(state, action)
    case types.SESSION_SET_APPLICATION: return setApplication(state, action)
    case types.SHOW_MENU: return showMenu(state, action)
    case types.OPEN_NEWS_BRIEF: return openNewsBrief(state, action)
    case types.CLOSE_NOTIFICATION: return closeNotification(state, action)
    case types.QUICK_NAVIGATE_SET_VALUE: return quickNavigateSetValue(state, action)
    case types.QUICK_NAVIGATE_SET_VISIBLE: return quickNavigateSetVisible(state, action)
    case types.ACCOUNT_SEARCH: return accountSearch(state, action)
    case types.ACCOUNT_SEARCH_FAIL: return accountSearchFailure(state, action)
    case types.ACCOUNT_SEARCH_SUCCESS: return accountSearchSuccess(state, action)
    case types.SET_JAIL_BROKEN: return setJailBroken(state, action)

    case types.SESSION_LOAD :
      return {
        ...state,
        refreshingSession: true,
        error: null
      }

    case types.SESSION_LOAD_FAIL:
      return {
        ...state,
        refreshingSession: false,
        error: action.error
      }

    case types.UPDATE_TIME_LEFT:
      return {
        ...state,
        timeLeft: action.timeLeft
      }

    default: return state
  }
}

const reducer = () => (state, action) => {
  return {
    ...root(state, action),
    options: optionsReducer((state && state.options), action),
    contactUsers: contactUserReducer((state && state.contactUsers), action)
    // [withKeyNavigationDomain]: withKeyNavigationReducer((state && state[withKeyNavigationDomain]), action)
  }
}

export default reducer
