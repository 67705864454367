import React from 'react'
import PropTypes from 'prop-types'
import * as iconTypes from '../../constants/iconTypes'
import Icon from '../Icon'
import * as links from '../../constants/links'
import convertModuleCodeToLabel from '../../util/convertModuleCodeToLabel'

import './index.scss'
import { translateComponent } from '../../util/translate'
const t = translateComponent('ApplicationsMenu')

const convertToIconType = code => {
  switch (code.toLowerCase()) {
    case 'dmv123':
      return iconTypes.DMV123
    case 'vehiclehistory':
    case 'titlecheck':
      return iconTypes.TITLECHECK
    case 'addtag':
      return iconTypes.ADDTAG
    case 'autoclear':
      return iconTypes.AUTOCLEAR
    case 'nmvtisvehicleupload':
      return iconTypes.JSI
    case 'elt':
      return iconTypes.ELT
    case 'directpostoffice':
      return iconTypes.DPO
    case 'admin':
      return iconTypes.ADMIN
    case 'accountMgt':
      return iconTypes.AMGT
    case 'tnportal':
      return iconTypes.TNPORTAL
    case 'arc':
      return iconTypes.ARC
    case 'vsq':
      return iconTypes.VSQ
    default:
      return iconTypes.DEFAULT_APP
  }
}

const renderIcon = code => {
  if (!code) return null
  return Icon({ type: convertToIconType(code) })
}

const mapModuleToIconLink = m => (
  <a href={m.url} title={m.label} key={m.code} className='applications-link'>
    {renderIcon(m.code)}
    <span className='applications-label'>{convertModuleCodeToLabel(m.code, m.label)}</span>
  </a>
)

const renderApps = apps => apps.map(mapModuleToIconLink)

const renderDashboardLink = () => (
  <div className='dash-div'>
    <a href={links.DASHBOARD}>{t('dashboard')}</a>
  </div>
)

const ApplicationsMenu = ({ apps }) => (
  <div className='applications-menu'>
    {renderDashboardLink()}
    {renderApps(apps)}
  </div>
)

ApplicationsMenu.propTypes = {
  apps: PropTypes.array.isRequired
}

export default ApplicationsMenu
