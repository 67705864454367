import AppLayout from './AppLayout'
import { connect } from 'react-redux'
import getSubApps from '../../util/getSubApps'

const mapStateToProps = state => {
  const subModules = getSubApps(state.appName, state.apps)
  const subSubModules = getSubApps(state.subAppName, subModules)
  return {
    appName: state.appName,
    subAppName: state.subAppName,
    subSubAppName: state.subSubAppName,
    subModules,
    subSubModules
  }
}

export default connect(mapStateToProps)(AppLayout)
