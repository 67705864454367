import React from 'react'
import PropTypes from 'prop-types'
import './index.scss'

const Glass = ({ children, className, onClick }) =>
  <div className={'addnav-glass' + (className ? ' ' + className : '')} onClick={onClick}>{children}</div>

Glass.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func
}

export default Glass
