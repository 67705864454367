import SubBar from './SubSubBar'
import { connect } from 'react-redux'

import getSubApps from '../../util/getSubApps'

const mapStateToProps = state => {
  const subModules = getSubApps(state.appName, state.legacyModules)
  const subSubModules = getSubApps(state.subAppName, subModules)
  return {
    appName: state.subSubAppName,
    modules: subSubModules
  }
}

export default connect(mapStateToProps)(SubBar)
