import NewsBrief from './NewsBrief'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../actions/session'
import * as notificationTypes from '../../constants/notificationTypes'
import { useCallback } from 'react'

const isUnreadNewsBrief = notification => notification.type === notificationTypes.NEWS_BRIEF && !notification.read

const reduceToFirst = (selected, current) => selected || current

const Component = props => {
  const dispatch = useDispatch()

  const nProps = useSelector(s => {
    const newsBrief = s.notifications.filter(isUnreadNewsBrief).reduce(reduceToFirst, null)
    if (!newsBrief) return {}
    return {
      uuid: newsBrief.uuid,
      title: newsBrief.title,
      status: newsBrief.level,
      date: newsBrief.start,
      body: newsBrief.body
    }
  })

  const handleClose = useCallback(ev => {
    ev.preventDefault()
    dispatch(actions.closeNotification(nProps.uuid))
  }, [nProps.uuid, dispatch])

  if (!nProps.uuid) return null

  return (
    <NewsBrief {...props} {...nProps} close={handleClose} />
  )
}

export default Component
