import sortQuickActions from './sortQuickActions'

const MAX_ACTION_RESULTS = 40

const cleanMatchString = s => (s || '').replace(/[^0-9a-z]/gi, '').toLowerCase()

const matchesSearch = value => quickAction => value && cleanMatchString(quickAction.label).match(cleanMatchString(value))

const ARC_URL = 'https://arc.add123.com'

export const fromDateProvider = serverTime => {
  const date = serverTime ? new Date(serverTime) : new Date()
  if (isNaN(date.valueOf())) {
    return ''
  }
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `&fromDate=${year}-${month}-${day}`
}

const determineUrl = a => {
  if (a.type === 'link') return a.url
  if (a.type === 'USER') return ARC_URL + '/user/' + a.userUuid
  if (a.type === 'COMPANY') return ARC_URL + '/company/' + a.companyUuid
  if (a.type === 'TRANSACTION') {
    const defaultTransUrl = ARC_URL + '/transactions?transactionSearch=' + a?.additionalFields?.transUuid
    return a.additionalFields?.transDate ? defaultTransUrl + fromDateProvider(a.additionalFields?.transDate) : defaultTransUrl
  }
  return ''
}

const setUrl = a => {
  return {
    ...a,
    url: determineUrl(a)
  }
}

const emptyQuickSearch = {
  title: '',
  id: '',
  uuid: '',
  type: '',
  active: '',
  companyName: '',
  dbaName: '',
  state: '',
  city: '',
  companyType: '',
  url: '#'
}

const addExtraInfo = state => account => {
  account = { ...emptyQuickSearch, ...account }
  account.search = state.quickNavigate.value
  if (account.type === 'link') {
    account.title = account.label
    account.active = true
  }
  return account
}

const quickActionSelector = state => {
  const value = state.quickNavigate.value
  let quickActions = buildQuickLinks(state).filter(matchesSearch(value))
  const accountSearch = state.quickNavigate.accountSearch

  quickActions = quickActions
    .concat(accountSearch)
    .map(setUrl)
    .map(addExtraInfo(state))
    .slice(0, MAX_ACTION_RESULTS)

  return sortQuickActions(uniqueBy(quickActions, getUrl), value)
}

const uniqueBy = (a, key) => {
  const seen = {}
  return a.filter(item => {
    const k = key(item)
    return seen[k] ? false : (seen[k] = true)
  })
}

const getUrl = o => o.url

const dashboardApp = {
  type: 'link',
  label: 'Dashboard',
  url: 'https://dashboard.add123.com/'
}

const buildQuickLinks = ({ links, apps }) => {
  const linkActions = links.reduce(reduceLinkToQuickAction, [])
  const appActions = apps.reduce(reduceAppToQuickAction, [])
  const dashboardActions = [dashboardApp]
  return [...linkActions, ...appActions, ...dashboardActions]
}

const reduceLinkToQuickAction = (a, link) => {
  if (link.url) a.push(mapLinkToQuickAction(link))
  if (link.subLinks) a = a.concat(link.subLinks.map(mapLinkToQuickAction))
  return a
}

const mapLinkToQuickAction = link => ({
  type: 'link',
  label: link.label,
  url: link.url
})

const reduceAppToQuickAction = (a, app) => {
  if (app.url) a.push(mapLinkToQuickAction(app))
  if (app.subApps) a = a.concat(app.subApps.map(mapAppToQuickAction))
  return a
}

const mapAppToQuickAction = app => ({
  type: 'link',
  label: app.label,
  url: app.url
})

export default quickActionSelector
