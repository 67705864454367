import * as types from '../constants/actionTypes'

export const quickNavigateSetValue = value => ({
  type: types.QUICK_NAVIGATE_SET_VALUE,
  value
})

export const quickNavigateSetVisible = visible => ({
  type: types.QUICK_NAVIGATE_SET_VISIBLE,
  visible
})

export const accountSearch = search => ({
  type: types.ACCOUNT_SEARCH,
  search
})

export const accountSearchSuccess = accounts => ({
  type: types.ACCOUNT_SEARCH_SUCCESS,
  accounts
})

export const accountSearchFailure = error => ({
  type: types.ACCOUNT_SEARCH_FAIL,
  error
})
