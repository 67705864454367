import React from 'react'
import PropTypes from 'prop-types'
import FoldingSection from '../FoldingSection'
import mapMenuLink from '../../util/mapMenuLink'
import Icon from '../Icon'
import * as iconTypes from '../../constants/iconTypes'
import './index.scss'

const style = (external, admin, loading) => {
  let s = 'menu-link'
  if (external) s += ' external-link'
  if (admin) s += ' admin-link'
  if (loading && !external) s += ' selected-loading'
  return s
}

const externalIcon = external => {
  if (!external) return null
  return <Icon type={iconTypes.EXTERNAL} />
}

const MenuLink = ({ label, url, subLinks, external, admin, handleClick, loading }) => {
  if (subLinks && subLinks.length) {
    return <FoldingSection additionalClassName={admin ? 'admin' : ''} label={label}>{subLinks.map(mapMenuLink)}</FoldingSection>
  }
  const params = {
    onClick: handleClick,
    href: url,
    title: label,
    className: style(external, admin, loading),
    target: external ? '_blank' : ''
  }
  return <a {...params}>{label} {externalIcon(external)}</a>
}

MenuLink.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  subLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
  external: PropTypes.bool.isRequired,
  admin: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired
}

export default MenuLink
