import i18next from 'i18next'
import en from '../translations/en/translation.json'

i18next.init({
  lng: 'en',
  resources: {
    en: {
      translation: en
    }
  }
})

const t = prefix => key => i18next.t(prefix + '.' + key, { defaultValue: '** MISSING LANG KEY [' + prefix + '.' + key + '] **' })

// FIXME legacy
export const translateComponent = prefix => t(prefix)

export default t
