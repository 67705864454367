import { createStore, compose } from 'redux'
import createReducer from '../reducers'
import createMergeEnhancer from './mergeStateEnhancer'
import createMiddlewareEnhancer from './middlewareEnhancer'
import createMock from './mock'

const createEnhancer = () => {
  let enhancer = createMiddlewareEnhancer()
  if (process.env.NODE_ENV !== 'production') {
    enhancer = compose(enhancer, createMergeEnhancer)
    enhancer = compose(enhancer, createMock)
  }
  return enhancer
}

const create = () => createStore(createReducer(), createEnhancer())

export default create
