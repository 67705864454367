import NavBar from './NavBar'
import { useSelector } from 'react-redux'

const Component = props => {
  const nProps = useSelector(s => ({
    isLoggedIn: s.isLoggedIn,
    initialized: s.initialized,
    apps: s.apps,
    isShadowUser: s.user.isShadowUser,
    userRealName: s.user.realName,
    companyName: s.company.name,
    companyCredit: s.company.credit,
    isAdmin: s.user.isAdmin,
    demoModeIsEnabled: window.ADD123.demo.isEnabled()
  }))

  props = { ...props, ...nProps }

  return (
    <NavBar {...props} />
  )
}

export default Component
