import ReactDOM from 'react-dom'

import ADD123 from '../api'
import { version } from '../../package.json'
import * as types from '../constants/actionTypes'
import * as sessionActions from '../actions/session'
import * as appActions from '../actions/appLayout'
import { CALLBACK_ARGUMENT, CALLBACK_ERROR_ARGUMENT, ERROR_ACTION, WAIT_FOR_ACTION } from 'redux-wait-for-action'
import * as redirects from '../util/redirect'
import * as callback from '../util/sessionExpiredCallback'
import expireSessionService from '../service/expireSession'
import { baseUrlCache, featureFlagsCache, overriddenUrlCache } from '../util/cache'
import { DEFAULT_BASE_URL } from '../constants/urls'
import * as featureFlags from '../constants/featureFlags'
import Footer from '../components/Footer'

const DEFAULT_FEATURE_FLAGS = {
  [featureFlags.REDIRECT_JAILED_USERS]: true,
  [featureFlags.CAN_ACTIVATE_OBO]: false
}

const getSessionSuccessArgumentCb = action => action.session
const getSessionFailureArgumentCb = action => action.error

export function getSessionAndWait (ignoreCache, loginRedirect, sessionExpiredCallback) {
  ADD123.setOptions({ ignoreCache, loginRedirect, sessionExpiredCallback })
  const action = sessionActions.load()
  return {
    ...action,
    [WAIT_FOR_ACTION]: types.SESSION_LOAD_SUCCESS,
    [ERROR_ACTION]: types.SESSION_LOAD_FAIL,
    [CALLBACK_ARGUMENT]: getSessionSuccessArgumentCb,
    [CALLBACK_ERROR_ARGUMENT]: getSessionFailureArgumentCb
  }
}

const setup = store => {
  ADD123.getVersion = () => version

  ADD123.login = () => redirects.redirectToLogin()

  ADD123.logout = () => expireSessionService(false).then(res => redirects.redirectToLogin())

  ADD123.setApplication = ({ appName, subAppName, subSubAppName } = {}) => store.dispatch(sessionActions.setApplication(appName, subAppName, subSubAppName))

  const urls = {}

  ADD123.urlRegister = (appCode, appUrl) => { urls[appCode] = appUrl }

  ADD123.urlOverride = (appCode, appUrl) => {
    const data = overriddenUrlCache.get() || {}
    data[appCode] = appUrl
    overriddenUrlCache.update(data)
  }

  ADD123.urlGet = appCode => {
    const overriddenUrl = (overriddenUrlCache.get() || {})[appCode]
    if (overriddenUrl) {
      console.log('****INFO**** using an overridden URL for ', appCode, overriddenUrl)
      return overriddenUrl
    }
    return urls[appCode] || ''
  }

  ADD123.urlList = () => {
    return { ...urls, ...overriddenUrlCache.get() }
  }

  ADD123.urlReset = appCode => {
    if (!appCode) return overriddenUrlCache.reset()
    const data = overriddenUrlCache.get() || {}
    delete data[appCode]
    overriddenUrlCache.update(data)
  }

  ADD123.baseUrl = baseUrlCache.get() || DEFAULT_BASE_URL

  ADD123.setBaseUrl = url => baseUrlCache.update(url)

  ADD123.getSession = ({
    loginRedirect = true,
    ignoreCache = false,
    sessionExpiredCallback = callback.get()
  } = {}) => store.dispatch(getSessionAndWait(ignoreCache, loginRedirect, sessionExpiredCallback)).catch(() => store.dispatch(sessionActions.sessionExpired(loginRedirect)))

  ADD123.setOptions = ({
    ignoreCache = false,
    loginRedirect = true,
    sessionExpiredCallback = callback.get()
  } = {}) => store.dispatch(appActions.setOptions(ignoreCache, loginRedirect, sessionExpiredCallback))

  ADD123.drawFooter = selector => {
    const element = selectFooterDiv(selector)
    ReactDOM.render(
      <Footer />, element
    )
  }

  const selectFooterDiv = selector => {
    if (selector) {
      return document.querySelector(selector)
    } else {
      const body = document.body
      const footerDiv = document.createElement('div')
      body.insertAdjacentElement('beforeend', footerDiv)
      return footerDiv
    }
  }

  ADD123.getFeatureFlags = () => ({ ...DEFAULT_FEATURE_FLAGS, ...featureFlagsCache.get() })

  ADD123.getFeatureFlag = type => ADD123.getFeatureFlags()[type]

  ADD123.setFeatureFlag = (type, value) => featureFlagsCache.update({ ...ADD123.getFeatureFlags(), [type]: value })

  const gaSend = event => {
    try {
      if (!event.path) {
        window.ga('send', {
          hitType: 'event',
          eventCategory: event.eventCategory,
          eventAction: event.eventAction
        })
      } else {
        window.ga('set', 'page', event.path)
        window.ga('send', 'pageview')
      }
    } catch (err) {
      console.log(err)
    }
  }

  ADD123.trackTransaction = ({ product, transtype }) => {
    if (!product || !transtype) throw new Error('Product and Transtype Required for ADD123 Tracker')
    gaSend({ eventCategory: product, eventAction: transtype })
  }

  ADD123.trackVisit = ({ path }) => {
    if (!path) throw new Error('Path Required for ADD123 Tracker')
    gaSend({ path })
  }

  const DEMO_MODE_ENABLED_KEY = 'ADDNAV_DEMO_MODE'
  const listeners = []
  ADD123.demo = {}

  ADD123.demo.addListener = listener => {
    listeners.push(listener)
  }

  ADD123.demo.enable = () => {
    listeners.forEach(listener => listener(true))
    window.sessionStorage.setItem(DEMO_MODE_ENABLED_KEY, 'true')
    window.location.reload()
  }

  ADD123.demo.disable = () => {
    listeners.forEach(listener => listener(false))
    window.sessionStorage.removeItem(DEMO_MODE_ENABLED_KEY)
    window.location.reload()
  }

  ADD123.demo.isEnabled = () => window.sessionStorage.getItem(DEMO_MODE_ENABLED_KEY) === 'true'

  if (process.env.NODE_ENV !== 'production') {
    ADD123.loadNotification = notification => store.dispatch(({
      type: 'LOAD_NOTIFICATION',
      notification
    }))
  }
}

export default setup
