import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon'
import * as iconTypes from '../../constants/iconTypes'
import * as dropMenuTypes from '../../constants/dropMenuTypes'
import './index.scss'

import { translateComponent } from '../../util/translate'
const t = translateComponent('NavBar')

const isActiveIcon = (dropMenuType, menuName) => menuName === dropMenuType.toLowerCase()

const mapIconType = (isShadowUser, dropMenuType) => {
  switch (dropMenuType) {
    case dropMenuTypes.APPS: return iconTypes.APPS
    case dropMenuTypes.NOTIFICATIONS: return iconTypes.NOTIFICATIONS
    default: return isShadowUser ? iconTypes.SHADOW_MAN : iconTypes.ACCOUNT
  }
}

const determineAccountTitle = (userRealName, companyName) => {
  if (!userRealName && !companyName) return t('account')
  let s = ''
  if (userRealName) s += userRealName
  if (companyName) s += ' (' + companyName + ')'
  return s
}

const getTitle = (dropMenuType, userRealName, companyName) => {
  switch (dropMenuType) {
    case dropMenuTypes.APPS:
      return t('nav')
    case dropMenuTypes.NOTIFICATIONS:
      return t('notifications')
    case dropMenuTypes.ACCOUNT:
      return determineAccountTitle(userRealName, companyName)
    default:
      return null
  }
}

const NavbarIcon = ({ dropMenuType, menuName, onClick, userRealName, companyName, isShadowUser, children, className, onKeyUp }) => {
  const title = getTitle(dropMenuType, userRealName, companyName)
  const style = 'navbar-icon' + (className ? ' ' + className : '')
  return (
    <span title={title} className={style} onClick={onClick} onKeyUp={onKeyUp} tabIndex={0}>
      <Icon isActive={isActiveIcon(dropMenuType, menuName)} type={mapIconType(isShadowUser, dropMenuType)} />
      {children}
    </span>
  )
}

NavbarIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  menuName: PropTypes.string.isRequired,
  dropMenuType: PropTypes.string.isRequired,
  userRealName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  isShadowUser: PropTypes.bool.isRequired,
  children: PropTypes.element,
  className: PropTypes.string,
  onKeyUp: PropTypes.func.isRequired
}

export default NavbarIcon
