import React from 'react'
import PropTypes from 'prop-types'
import Notification from '../Notification'
import translate from '../../util/translate'
import './index.scss'

const t = translate('Notifications')

const renderNotificationsEmpty = () => <div className='notifications-empty'>{t('emptyNotifications')}</div>

const notificationToElement = data =>
  <li key={data.uuid}>
    <Notification
      key={data.uuid}
      uuid={data.uuid}
      type={data.type}
      status={data.level}
      date={data.start}
      body={data.body}
      title={data.title}
      read={data.read}
    />
  </li>

const Notifications = ({ notifications }) => {
  if (!notifications || notifications.length < 1) return renderNotificationsEmpty()
  return (
    <ul className='notifications-menu'>
      {notifications.map(notificationToElement)}
    </ul>
  )
}

Notifications.propTypes = {
  notifications: PropTypes.array.isRequired
}

export default Notifications
