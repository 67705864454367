import { encode } from '../util/queryString'

export const redirect = url => window.location.assign(url)

const loginUrl = (message, username) => 'https://login-api.add123.com/authorize?' + encode({
  client_id: '4ff8d53d-a01b-4f82-9173-1d67d79c0e83',
  redirect_uri: 'https://login-api.add123.com/session',
  scope: 'web_session',
  response_type: 'code',
  grant_type: 'authorization_code',
  state: `dest=${encodeURIComponent(window.location.href)}`,
  username,
  message
})

export const redirectToLogin = (username, message) => redirect(loginUrl(username, message))
