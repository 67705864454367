import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon'
import * as iconTypes from '../../constants/iconTypes'
import Markdown from '../Markdown'
import './index.scss'
import { MoveFocusInside } from 'react-focus-lock'

const typeToStyle = type => type && type.replace('_', '-').toLowerCase()

const getIconTypeFromBroadcastStatus = status => {
  switch (status) {
    case 'ERROR': return iconTypes.TRI_WARNING_LINE
    case 'INFO': return iconTypes.SQ_INFO
    case 'WARNING': return iconTypes.TRI_WARNING
    case 'SUCCESS': return iconTypes.SUCCESS
    default: return iconTypes.SQ_INFO
  }
}

const Broadcast = ({ uuid, status, body, closeMessage, onKeyUp }) =>
  <MoveFocusInside>
    <div tabIndex={0} className={'broadcast-message broadcast-' + typeToStyle(status) + ' broadcast-' + uuid}>
      <span tabIndex={0} className='addnav-close-button' onKeyUp={onKeyUp} onClick={closeMessage} title='Hide'>
        <Icon type={iconTypes.CLOSE} />
      </span>
      <div className={'broadcast-left-col broadcast-' + typeToStyle(status)}>
        <Icon type={getIconTypeFromBroadcastStatus(status)} />
      </div>
      <div className='addnav-broadcast-message'>
        <Markdown source={body} />
      </div>
    </div>
  </MoveFocusInside>

Broadcast.propTypes = {
  status: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  closeMessage: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func.isRequired
}

export default Broadcast
