import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import AccountSearchResult from '../AccountSearchResult'
import Glass from '../Glass'
import withScrollIntoView from '../../util/withScrollIntoView'
import { context } from './KeyNavigator'
import './index.scss'

const key = a => {
  switch (a.type) {
    case 'USER':
      return a.type + a.userUuid
    case 'COMPANY':
      return a.type + a.companyUuid
    case 'TRANSACTION':
      return a.type + (a?.additionalFields?.transId ?? '')
    default:
      return a.type + a.url
  }
}

const AccountSearchResultWithScroll = withScrollIntoView(AccountSearchResult)

const toResultElement = (navigationIndex, close) => (account, index) =>
  <AccountSearchResultWithScroll onClick={close} key={key(account)} {...account} selected={navigationIndex === index} />

const DropPanel = ({ accounts, navigationIndex, close }) =>
  <div className='drop-panel'>
    {accounts.map(toResultElement(navigationIndex, close))}
  </div>

const AccountSearch = ({ quickActions, loading, search, handleSearchChange, closeIfClickedOn, close }) => {
  const navigationIndex = useContext(context)

  return (
    <Glass className='account-search' onClick={closeIfClickedOn}>
      <div onClick={closeIfClickedOn} className={'addnav-acct-search' + (loading && search ? ' loading' : '')}>
        <input
          autoFocus
          className='form-control'
          placeholder='search'
          value={search}
          onChange={handleSearchChange}
          type='text'
        />
        {quickActions.length && search?.trim().length >= 3 ? <DropPanel close={close} navigationIndex={navigationIndex} accounts={quickActions} /> : null}
      </div>
    </Glass>
  )
}

DropPanel.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  navigationIndex: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired
}

AccountSearch.propTypes = {
  quickActions: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  search: PropTypes.string.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  closeIfClickedOn: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired
}

export default AccountSearch
