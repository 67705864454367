import React from 'react'
import PropTypes from 'prop-types'
import './index.scss'
import '../../addicons.css'

const allowSpaces = search => search.split('').join('(?:[^a-zA-Z0-9]*)?')

const cleanSearch = search => search.replace(/[^a-zA-Z0-9]/g, '')

export const splitter = (fullText, searchText) => {
  if (!fullText || !searchText) return null
  const pattern = '([\\s\\S]*?)(' + allowSpaces(cleanSearch(searchText)) + ')([\\s\\S]*)'
  const regex = new RegExp(pattern, 'mi')
  const matches = regex.exec(fullText)
  if (!matches) return null
  return matches.slice(1, 4)
}

const Highlight = ({ search, children }) => {
  const parts = splitter(children, search)
  if (!parts || !parts.length) {
    return <span>{children}</span>
  }
  return <span>{parts[0]}<span className='highlighted-search-text'>{parts[1]}</span>{parts[2]}</span>
}

Highlight.propTypes = {
  search: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired
}

export default Highlight
