import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import AddLogo from '../AddLogo'
import Markdown from '../Markdown'
import OverlayModal from '../OverlayModal'
import translate from '../../util/translate'

import './index.scss'

const t = translate('NewsBrief')

const ContinueButton = ({ close, name }) => <button onClick={close} className='continue-button' name={'continue ' + name}>{t('continue')}</button>

const NewsBrief = ({ title, body, close }) => {
  const notifications = useSelector(state => state.notifications)
  const hasSticky = notifications[0]?.sticky || false

  return (
    <OverlayModal>
      <AddLogo />
      <div className='content'>
        <h2 className='title'>{title}</h2>
        <Markdown source={body} />
      </div>
      {hasSticky ? null : <ContinueButton name='' close={close} />}
    </OverlayModal>
  )
}

NewsBrief.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired
}

ContinueButton.propTypes = {
  close: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
}

export default NewsBrief
