import { all, takeEvery, call, select } from 'redux-saga/effects'
import { legacyModuleCache } from '../util/cache'
import * as types from '../constants/actionTypes'

export default function * cacheLegacyModules () {
  yield all([
    takeEvery(types.SESSION_LOAD_SUCCESS, callLegacyModulesSaga)
  ])
}

export function * callLegacyModulesSaga () {
  const legacyModules = yield select(state => state.legacyModules)
  yield call(legacyModuleCache.update, legacyModules)
}
