import { CLOSE_NOTIFICATION } from '../util/uris'
import mock from './mocks/closeBroadcastMessage'
import ajax from '../util/ajax'

const closeNotification = (uuid) => {
  const url = `${CLOSE_NOTIFICATION}/${uuid}/close`
  return ajax.post({ url, mock: mock })
}

export default closeNotification
