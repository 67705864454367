import React from 'react'
import PropTypes from 'prop-types'
import './index.scss'

export default class DropMenu extends React.Component {
  constructor (props) {
    super(props)
    this.setRef = this.setRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  setRef (node) {
    this.wrapperRef = node
  }

  clickedOnNavbarIcon (event) {
    try {
      return event.target.parentElement.className.match(/(?:navbar-icon)|(?:badge-wrapper)/) !== null
    } catch (err) {
      return false
    }
  }

  isScrollBarClick (event) {
    const width = event.currentTarget.documentElement.offsetWidth
    return event.pageX - width > 0
  }

  handleClickOutside (event) {
    if (!this.props.onOffClick) return
    if (!this.wrapperRef) return
    if (this.wrapperRef.contains(event.target)) return
    if (this.clickedOnNavbarIcon(event)) return
    if (this.isScrollBarClick(event)) return
    this.props.onOffClick(event)
  }

  render () {
    return (
      <div ref={this.setRef} className='addnav-dropmenu'>
        {this.props.children}
      </div>
    )
  }
}

DropMenu.propTypes = {
  onOffClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}
