export default function convertModuleCodeToLabel (code, label) {
  switch (code.toLowerCase()) {
    case 'dmv123':
      return 'DMV123'
    case 'nmvtisvehicleupload':
      return 'NMVTIS'
    case 'directpostoffice':
      return 'DPO'
    case 'vehiclehistory':
      return 'Title Check'
    default:
      return label
  }
}
