import Notification from './Notification'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import * as actions from '../../actions/session'

const Component = props => {
  const dispatch = useDispatch()

  const handleClick = useCallback(ev => {
    dispatch(actions.openNewsBrief(props.uuid))
  }, [props.uuid, dispatch])

  const handleKeyUp = useCallback(ev => {
    if (ev.keyCode === 13) {
      dispatch(actions.openNewsBrief(props.uuid))
    }
  }, [props.uuid, dispatch])

  return (
    <Notification {...props} onClick={handleClick} onKeyUp={handleKeyUp} />
  )
}

export default Component
