const formatAccountNumber = accountNumber => {
  if (!accountNumber) return ''
  accountNumber = '' + accountNumber
  if (!accountNumber.match(/^\d{8}$/)) {
    return accountNumber
  }
  return accountNumber.substring(0, 3) + '-' + accountNumber.substring(3, 5) + '-' + accountNumber.substring(5)
}

export default formatAccountNumber
