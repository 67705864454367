import AccountInformation from './AccountInformation'
import { useSelector } from 'react-redux'

const isAccountManagementLinkGroup = link => link.label && link.label.toLowerCase().includes('account management')
const isAddCreditLink = link => link.label && link.label.toLowerCase().includes('add credit')
const getAddCreditLink = state => {
  const links = state.links
  if (!links || !links.length) return null
  const accountManageLinks = links.filter(isAccountManagementLinkGroup)
  if (!accountManageLinks || !accountManageLinks.length || !accountManageLinks[0].subLinks) return null

  const addCreditLinks = accountManageLinks[0].subLinks.filter(isAddCreditLink)
  if (!addCreditLinks || !addCreditLinks.length) return null
  return addCreditLinks[0]
}

const Component = () => {
  const props = useSelector(s => ({
    companyCredit: s.company.credit,
    userRealName: s.user.realName,
    companyName: s.company.name,
    accountNumber: s.company.accountNumber,
    isShadowUser: s.user.isShadowUser,
    isJailedUser: s.user.isJailed,
    addCreditLink: getAddCreditLink(s)
  }))

  props.addCreditLink = useSelector(getAddCreditLink)

  return (
    <AccountInformation {...props} />
  )
}
export default Component
