export const DASHBOARD = 'https://dashboard.add123.com'
export const BREAK_JAIL = 'https://secure.add123.com/secure/addpassword_reset.asp?break_jail=t'

// LEGACY TABS ONLY
export const SHADOW_USER = '/secure/addadminshadowuser.asp'
export const PASSWORD_CHANGE = 'https://login.add123.com/#/change-password'
export const LOGOUT = '/secure/addabandon.asp'
export const ADD_WIKI = 'http://wiki.add123.com/index.php/Main_Page'
export const ADMIN_PORTAL = 'https://apps.add123.com/AdminPortal/AdminPortal.html'
export const MY_ACCOUNT = '/secure/addmyaccount.asp'
export const PREFERENCES = '/secure/addprofiles.asp?tmr=tabname_preferences'
export const ARCHIVE = '/secure/adduserrecordstable.asp?tmr=tabname_queryarchive'
export const ADMIN = '/secure/addadminreports.asp?tmr=tabname_admin'
export const ACCOUNT_MGT = '/secure/addadminedituser.asp?tmr=tabname_admin_account_mgt'
export const ARC = 'https://arc.add123.com'
export const USERS = '/secure/addadminedituser.asp'
export const COMPANIES = '/secure/addadmineditcompany.asp'
export const QUERIES = '/secure/archive.asp'
export const ADD_CREDIT = '/secure/addaddcredit.asp'
