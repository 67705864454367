import Notifications from './Notifications'
import { connect } from 'react-redux'

const mapStateToProps = state => {
  return {
    notifications: state.notifications
  }
}

export default connect(mapStateToProps)(Notifications)
