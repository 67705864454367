import React from 'react'
import PropTypes from 'prop-types'
import OverlayModal from '../OverlayModal'
import AddLogo from '../AddLogo'
import Markdown from '../Markdown'
import translate from '../../util/translate'

const t = translate('JailedNotification')

const buildJailMessage = (type, isAccountAdmin) => {
  switch (type) {
    case '2':
      return t('passwordUpdateMessage')
    case '4':
      return isAccountAdmin ? t('companyVerificationMessage') : t('companyVerificationNonContactMessage')
    case '5':
      return isAccountAdmin ? t('agreementOutOfDateMessage') : t('agreementOutOfDateNonContactMessage')
    case '7':
      return t('negativeCreditMessage')
    case '11':
      return t('securityQuestionsMessage')
    default:
      return t('genericJailedMessage')
  }
}

const companyJails = ['4', '5']

const requiresAdminAction = (type, isAccountAdmin) => companyJails.includes(type) && !isAccountAdmin

const ContinueButton = ({ close, name }) => <button onClick={close} className='continue-button' name={'continue ' + name}>{t('continue')}</button>

const BreakJailButton = ({ breakJail }) => <div className='text-right'><span onClick={breakJail}>{t('breakJail')}</span></div>

export const appendContactUsers = (markdown, users = []) => {
  const names = users.map(user => '* ' + user.realName)
  return markdown + '\n' + names.toString().replace(/,/gm, '\n')
}

const buildContactListMarkdown = contactUsers => contactUsers.length && contactUsers.length <= 10 ? appendContactUsers(t('adminListMessage'), contactUsers) : ''

const JailedNotification = ({ title, type, close, isAccountAdmin, contactUsers, breakJail, isShadowing }) =>
  <OverlayModal>
    <AddLogo />
    <div className='content'>
      <h2 className='title'>{title}</h2>
      <Markdown source={buildJailMessage(type, isAccountAdmin)} />
      {requiresAdminAction(type, isAccountAdmin) ? <Markdown source={buildContactListMarkdown(contactUsers)} /> : null}
      <Markdown source={t('footer')} />
    </div>
    {requiresAdminAction(type, isAccountAdmin) ? null : <ContinueButton name='' close={close} />}
    {isShadowing ? <BreakJailButton breakJail={breakJail} /> : null}
  </OverlayModal>

JailedNotification.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isAccountAdmin: PropTypes.bool.isRequired,
  isShadowing: PropTypes.bool.isRequired,
  companyId: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  contactUsers: PropTypes.array.isRequired,
  breakJail: PropTypes.func.isRequired
}

ContinueButton.propTypes = {
  close: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
}

BreakJailButton.propTypes = {
  breakJail: PropTypes.func.isRequired
}

export default JailedNotification
