import format from 'date-fns/format'

export const DATE_DISPLAY_FORMAT = 'M/D/YYYY h:mma'
export const DATE_SERVICE_FORMAT = 'YYYY-MM-DD[T]H:mm:ss.SSS[Z]'

function convertDateToUTC (date) {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds())
}

export function toServiceDate (date) {
  return format(convertDateToUTC(date), DATE_SERVICE_FORMAT)
}

export function fromEpoch (epoch) {
  const localReference = new Date(0)
  return format(localReference.setUTCSeconds(epoch / 1000), DATE_DISPLAY_FORMAT)
}

export default function dateFormat (sDate) {
  return format(sDate, DATE_DISPLAY_FORMAT)
}
