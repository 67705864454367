import { takeEvery, call, put, select, all } from 'redux-saga/effects'
import get from '../util/get'
import { readSessionBroadcastCache, sessionTrackingIdCache, jailBrokenCache } from '../util/cache'
import * as types from '../constants/actionTypes'
import * as actions from '../actions/session'
import service from '../service/getSession'
import selector from '../selector'
import * as callback from '../util/sessionExpiredCallback'
import isSessionExpiredError from '../util/isSessionExpiredError'
import isUnauthorizedError from '../util/isUnauthorizedError'

const MAX_RETRY_EXPIRE_COUNT = 2

const coalesce = (first, second) => first === null || first === undefined ? second : first

export default function * loadSession () {
  yield all([
    takeEvery(types.SESSION_LOAD, loader)
  ])
}

let retryExpireCount = 0

export function * loader (action) {
  const sessionExpiredCallback = yield select(selector('options.sessionExpiredCallback'))
  const ignoreCacheGlobal = yield select(selector('options.ignoreCache'))
  const ignoreCache = coalesce(action.ignoreCache, ignoreCacheGlobal)

  callback.set(sessionExpiredCallback)
  try {
    /*
     * pull session from store if it exists
     * - this can be disabled with action.ignoreCache=true
     */
    if (!ignoreCache) {
      const storedSession = yield select(selector('sessionCache'))
      const jailCache = yield jailBrokenCache.get()
      if (jailCache) {
        yield put(actions.setJailBroken(jailCache))
      }
      if (storedSession) {
        yield put(actions.loadSuccess(storedSession))
        return
      }
    }

    /*
     * pull the session from userapi and return it in the load success action
     * or throw an error
     */
    let res
    try {
      res = yield call(service)
    } catch (loadSessionErr) {
      if (isUnauthorizedError(loadSessionErr)) {
        throw new Error('SESSION_NOT_FOUND')
      }
    }
    const err = get(res, 'xmlerror.message', false)
    if (err) throw new Error(err)

    checkIfCurrentSession(res.sessionTrackingId)
    yield put(actions.loadSuccess(res))
    retryExpireCount = 0
  } catch (error) {
    /*
     * handle errors using the load failure action
     */
    const isSessionExpireError = isSessionExpiredError(error)
    if (isSessionExpireError && retryExpireCount < MAX_RETRY_EXPIRE_COUNT) {
      retryExpireCount++
      yield put(actions.sessionExpired())
    } else if (isSessionExpireError) {
      console.log('ADDNAV', 'Unable to load session after retry:', error)
    } else {
      console.log('failed to load session:', error)
    }
  }
}

const checkIfCurrentSession = sessionTrackingId => {
  if (!sessionTrackingId) return
  const sessionTrackingIdFromCache = sessionTrackingIdCache.get() || ''
  if (sessionTrackingIdFromCache && sessionTrackingId === sessionTrackingIdFromCache) return
  sessionTrackingIdCache.update(sessionTrackingId)
  clearSessionBroadcastCache()
}

const clearSessionBroadcastCache = () => readSessionBroadcastCache.update([])
