import React from 'react'
import ReactDOM from 'react-dom'
import sagas from './sagas'
import setupApi from './api/setup'
import { Provider } from 'react-redux'
import AppLayout from './components/AppLayout'
import store from './store'

import googleAnalytics from './util/googleAnalytics'

googleAnalytics()

const renderBody = (e, reduxStore) => {
  ReactDOM.render(
    <Provider store={reduxStore}>
      <AppLayout />
    </Provider>, e
  )
}

const insertAddnavToBody = reduxStore => {
  let e = document.getElementById('addnav')
  if (e === null) {
    e = document.createElement('div')
    e.id = 'addnav'
  }
  renderBody(e, reduxStore)
  document.body.insertBefore(e, document.body.firstChild)
}

sagas()
setupApi(store)

if (!document.body) {
  document.addEventListener('DOMContentLoaded', () => insertAddnavToBody(store))
} else {
  insertAddnavToBody(store)
}
