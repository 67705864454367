import AccountMenu from './AccountMenu'
import { useSelector } from 'react-redux'
import { useCallback } from 'react'

const logout = () => window.ADD123.logout()

const Component = () => {
  const links = useSelector(s => s.links)
  const handleKeyUp = useCallback(ev => {
    if (ev.keyCode === 13) {
      logout()
    }
  }, [])

  return (
    <AccountMenu links={links} logout={logout} onKeyUp={handleKeyUp} />
  )
}

export default Component
