import NavBarIcon from './NavbarIcon'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../actions/navBar'
import { useCallback } from 'react'

const Component = props => {
  const dispatch = useDispatch()
  const nProps = useSelector(s => ({
    menuName: s.menuName,
    userRealName: s.user.realName,
    companyName: s.company.name,
    isShadowUser: s.user.isShadowUser,
    isLoggedIn: s.isLoggedIn
  }))

  props = { ...props, ...nProps }

  const handleClick = useCallback(ev => {
    if (!props.isLoggedIn) return
    dispatch(actions.showMenu(props.menuName === props.dropMenuType ? '' : props.dropMenuType))
  }, [dispatch, props.isLoggedIn, props.menuName, props.dropMenuType])

  const handleKeyUp = useCallback(ev => {
    if (ev.keyCode === 13) {
      dispatch(actions.showMenu(props.menuName === props.dropMenuType ? '' : props.dropMenuType))
    }
  }, [dispatch, props.menuName, props.dropMenuType])

  return (
    <NavBarIcon {...props} onClick={handleClick} onKeyUp={handleKeyUp} />
  )
}

export default Component
