import * as RUNLEVELS from '../constants/runlevels'
import getRunLevel from './getCurrentRunlevel'
import ADD123 from '../api'

function getQueryString (params) {
  if (!params) {
    return
  }
  return Object.keys(params)
    .map(k => window.encodeURIComponent(k) + '=' + window.encodeURIComponent(params[k]))
    .join('&')
}

const absoluteUrl = url => url.startsWith('https://') || url.startsWith('http://')

function send (request) {
  if (getRunLevel() === RUNLEVELS.MOCK) {
    return request.mock(request)
  }

  return new Promise((resolve, reject) => {
    const method = request.method || 'GET'
    let qs = ''
    let query
    let body
    const requestHeaders = request.headers || {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }

    if (['GET', 'DELETE'].indexOf(method) > -1) {
      query = getQueryString(request.data)
      if (query) {
        qs = '?' + query
      }
    } else {
      // TODO: cleanup: currently non JSON can be sent to the server by setting request.body instead of request.data
      if (request.body) {
        body = request.body
      } else {
        body = JSON.stringify(request.data)
      }
    }

    const url = absoluteUrl(request.url) ? request.url : (ADD123.baseUrl + request.url + qs)
    const xhr = new window.XMLHttpRequest()

    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        try {
          resolve(JSON.parse(this.responseText))
        } catch (err) {
          reject(err)
        }
      } else {
        reject(this.statusText)
      }
    }

    xhr.onerror = function () {
      try {
        reject(JSON.parse(this.responseText))
      } catch (err) {
        reject(err)
      }
    }

    xhr.onabort = function () {
      resolve()
    }

    xhr.open(method, url)

    xhr.withCredentials = true

    for (const header in requestHeaders) {
      xhr.setRequestHeader(header, requestHeaders[header])
    }

    xhr.send(body)
  })
}

const ajax = {
  get: request => send({ method: 'GET', ...request }),
  post: request => send({ method: 'POST', ...request }),
  put: request => send({ method: 'PUT', ...request }),
  delete: request => send({ method: 'DELETE', ...request })
}

export default ajax
