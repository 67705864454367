const urlPathEquals = (urlA, urlB) => {
  if (!urlA || !urlB) return false
  const regex = /\S*?:\/\/\S*?\//
  let pathA = urlA.replace(regex, '')
  let pathB = urlB.replace(regex, '')
  if (!pathA.startsWith('/')) pathA = '/' + pathA
  if (!pathB.startsWith('/')) pathB = '/' + pathB

  return pathA.split('?')[0] === (pathB.split('?')[0])
}

export default urlPathEquals
