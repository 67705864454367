import React from 'react'
import PropTypes from 'prop-types'
import NavBarIcon from '../NavBarIcon'
import * as menuTypes from '../../constants/dropMenuTypes'
import './index.scss'
import '../../addicons.css'

const Badge = ({ unReadCount }) => {
  if (!unReadCount) return null
  return (
    <div className='addnav-badge-wrapper'>
      <div className='addnav-badge'>{unReadCount}</div>
    </div>
  )
}

const style = unReadCount => {
  let s = 'navbar-notification-icon'
  if (unReadCount) s += ' unread'
  return s
}

const NotificationsIcon = ({ unReadCount }) =>
  <NavBarIcon className={style(unReadCount)} dropMenuType={menuTypes.NOTIFICATIONS}>
    <Badge unReadCount={unReadCount} />
  </NavBarIcon>

Badge.propTypes = {
  unReadCount: PropTypes.number.isRequired
}

NotificationsIcon.propTypes = {
  unReadCount: PropTypes.number.isRequired
}

export default NotificationsIcon
