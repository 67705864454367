import { takeLatest } from 'redux-saga/effects'
import { SET_JAIL_BROKEN } from '../constants/actionTypes'
import { jailBrokenCache } from '../util/cache'

export default function * setJailBroken (action) {
  yield takeLatest(SET_JAIL_BROKEN, updateJailBrokenCache)
}

function * updateJailBrokenCache (action) {
  try {
    jailBrokenCache.update(action.isJailBroken)
    yield
  } catch (error) {
    console.log(error)
  }
}
