const accountSearch = request => Promise.resolve({
  response: [
    {
      uuid: 'f63dfbef-0313-4f4b-8628-bf9fc3d87018',
      type: 'transaction',
      transType: 'nmvtisppi',
      transData: '123456',
      active: true,
      username: '',
      realName: '',
      companyName: 'Auto Data Direct, Inc.',
      dbaName: 'AUTO DATA DIRECT',
      state: 'FL',
      city: 'TALLAHASSEE',
      companyType: 'TOW',
      isMaster: false
    },
    {
      id: 42801,
      uuid: 'fe35e855-6a78-4c30-a1a3-dffd813d6d01',
      type: 'user',
      active: true,
      username: 'chardin',
      realName: 'CHARLES HARDIN',
      companyName: 'PC AUTO SALES LLC',
      dbaName: 'PC AUTO SALES LLC',
      state: 'FL',
      city: 'JACKSONVILLE',
      isMaster: true
    }
  ]
})

export default accountSearch
