import { takeLatest, put, throttle, all, call } from 'redux-saga/effects'
import service from '../service/accountSearch'
import * as types from '../constants/actionTypes'
import * as actions from '../actions/quickNavigate'

const accountSearchSaga = function * (action) {
  yield all([
    takeLatest(types.ACCOUNT_SEARCH, fetchData),
    throttle(1000, types.QUICK_NAVIGATE_SET_VALUE, quickNavigate)
  ])
}

function * quickNavigate (action) {
  yield put(actions.accountSearch(action.value))
}

function * fetchData (action) {
  try {
    const accounts = yield call(service, action.search)
    yield put(actions.accountSearchSuccess(accounts))
  } catch (error) {
    yield put(actions.accountSearchFailure(error))
  }
}

export default accountSearchSaga
