import React from 'react'
import PropTypes from 'prop-types'

import './index.scss'

const isActiveModule = (module, appName) => module.code.toLowerCase() === appName.toLowerCase()

const moduleStyle = (module, appName) => isActiveModule(module, appName) ? 'active' : ''

const mapModuleToLink = appName => module =>
  <a href={module.url} key={module.code} className={moduleStyle(module, appName)}>{module.label}</a>

const renderLinks = (modules, appName) => modules.map(mapModuleToLink(appName))

const renderParentName = parent => {
  if (!parent) return
  return <h2 tabIndex={0} title={parent}>{parent}</h2>
}

const SubBar = ({ parent, modules, appName }) => {
  if (!modules.length) return null
  return (
    <div className={'addnav-subbar' + (parent ? ' primary-bar' : '')}>
      {renderParentName(parent)}
      {renderLinks(modules, appName)}
    </div>
  )
}

SubBar.propTypes = {
  parent: PropTypes.string,
  appName: PropTypes.string.isRequired,
  modules: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default SubBar
