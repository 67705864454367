import { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import JailedNotification from './JailedNotification'
import { REDIRECT_JAILED_USERS } from '../../constants/featureFlags'
import getActiveJailSelector from '../../selectors/getActiveJailSelector'
import { redirect } from '../../util/redirect'
import urlPathEquals from '../../util/urlPathEquals'

import * as actions from '../../actions/session'

const REDIRECT_BASE_URL = 'https://secure.add123.com'

const hasFeatureFlag = feature => window.ADD123.getFeatureFlag(feature)

const currentPageOnRedirectUrl = (currentPage, redirectUrl) => urlPathEquals(currentPage, redirectUrl)

const currentPageOnOtherAllowedScripts = (currentPage, allowedScripts) => {
  if (!allowedScripts) return false

  const scripts = allowedScripts.split(',')
  for (const script of scripts) {
    if (currentPage.includes(script)) return true
  }
  return false
}

const shouldShowJailedNotification = props => {
  const currentPage = window.location.href
  return (hasFeatureFlag(REDIRECT_JAILED_USERS) &&
    !props.isJailBroken &&
    !currentPageOnRedirectUrl(currentPage, props.redirectUrl) &&
    !currentPageOnOtherAllowedScripts(currentPage, props.otherAllowedScripts) &&
    props.uuid && props.title && props.type
  )
}

const convertRelativeUrlToAbsolute = url => {
  if (!url) return
  if (url.substring(0, 4) === 'http') return url
  return REDIRECT_BASE_URL + url
}

const Component = props => {
  const dispatch = useDispatch()
  const nProps = useSelector(s => ({
    ...getActiveJailSelector(s),
    isAccountAdmin: s.user.isAccountAdmin,
    isJailBroken: s.isJailBroken,
    companyId: s.company.uuid,
    contactUsers: s.contactUsers.users,
    isShadowing: s.user.isShadowUser
  }))

  props = { ...props, ...nProps }

  const shouldShow = shouldShowJailedNotification(props)

  useEffect(() => {
    if (shouldShow && props.contactUsers.length === 0) {
      dispatch(actions.getContactUsers())
    }
  }, [props.contactUsers, shouldShow, dispatch])

  const close = useCallback(ev => {
    redirect(convertRelativeUrlToAbsolute(props.redirectUrl))
    ev.preventDefault()
  }, [props.redirectUrl])

  const breakJail = useCallback(ev => {
    dispatch(actions.setJailBroken(true))
    ev.preventDefault()
  }, [dispatch])

  if (!shouldShow) return null

  return (
    <JailedNotification {...props} close={close} breakJail={breakJail} />
  )
}

export default Component
