import React from 'react'
import MenuLink from '../components/MenuLink'

const mapMenuLink = link => (
  <MenuLink
    key={link.label + (link.url || '')}
    label={link.label}
    url={link.url || ''}
    subLinks={link.subLinks || []}
    admin={link.admin || false}
    external={link.external || false}
  />
)

export default mapMenuLink
